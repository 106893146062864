export interface IModal {
  modalHeader: IModalHeader;
  modalBody: string;
  modalContent?: IModalContent;
  modalFooter?: IModalFooter;
  modalStyle?: IModalStyle;
}

export interface IModalHeader {
  title: string;
  text?: string;
  icon?: IModalHeaderIcon;
}

export interface IModalStyle {
  width?: string;
}

export interface IModalContent {
  title?: string;
  text?: string;
  contentType?: string;
}

export interface IModalHeaderIcon {
  iconClass: string;
}

export interface IModalFooter {
  cancelButtonText?: string;
  confirmButtonText?: string;
  checkFormValidation?: boolean;
  hideConfirmButton?: boolean;
}

export enum ModalButtonType {
  CANCEL = 'CANCEL',
  CONFIRM = 'CONFIRM',
  CROSS = 'CROSS',
}

export enum FormActionType {
  DELETE = 'DELETE',
  DECOMMISSION = 'DECOMMISSION',
  COPY = 'COPY',
}
