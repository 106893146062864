<header>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <div
        type="button"
        class="btn-toggle-sidebar toggle-left-margin"
        (click)="sidebarToggle()"
      >
        <img
          src="{{ icons.HAMBURGER }}"
          (appClickOutside)="autoHideSidebar()"
          alt="Menu"
        />
      </div>
      <div
        type="button"
        class="me-auto logo-left-margin"
        (click)="backToHome()"
      >
        <img src="{{ icons.CMGR_LOGO }}" alt="Logo" />
      </div>
      <div>
        <img src="{{ icons.NOTIFICATION }}" alt="Notification" />
      </div>
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <div class="profile-img">{{ initials }}</div>
          <a
            class="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            >{{ userData?.firstName }}</a
          >
          <ul class="dropdown-menu dropdown-menu-end">
            <li><a class="dropdown-item disabled" href="#">My Account</a></li>
            <li><a class="dropdown-item disabled" href="#">Settings</a></li>
            <li><hr class="dropdown-divider" /></li>
            <li>
              <a class="dropdown-item cursor-pointer" (click)="logout()"
                >Logout</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</header>
