<div class="data-grid">
  <p-table
    #commonDataGridTable
    [resizableColumns]="resizableColumns"
    [value]="tableData"
    styleClass="p-datatable-striped p-datatable-sm"
    [paginator]="pagination"
    [(selection)]="selectedRows"
    [totalRecords]="tableData ? tableData.length : 0"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '43rem', 'min-height': '6rem' }"
    [rows]="pageSize"
    [expandedRowKeys]="expandedRows"
  >
    <ng-template pTemplate="header">
      <tr>
        <th class="checkboxWidth" id="checkbox">
          <p-tableHeaderCheckbox
            (click)="onSelectRow()"
            (keyDown)="$event.preventDefault()"
          ></p-tableHeaderCheckbox>
          <span>Copy</span>
        </th>
        <th style="width: 5rem"></th>
        <th
          *ngFor="let col of tableColumns | keyvalue : sortInOrder"
          class="table-header"
          [id]="col.key"
          [pSortableColumn]="col.key"
          [pSortableColumnDisabled]="col.value?.['sort'] ? false : true"
          [style.width.px]="col.value?.['width']"
          pResizableColumn
        >
          {{ col.value?.["header"] }}
          <p-sortIcon
            *ngIf="col.value?.['sort']"
            [field]="col.key"
          ></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data let-expanded="expanded">
      <tr>
        <td class="checkbox">
          <p-tableCheckbox
            [value]="data"
            (click)="onSelectRow()"
            (keyDown)="$event.preventDefault()"
            *ngIf="
              checkVisiblity(
                data?.questionBankGroupCode,
                data?.isSourceAutoQualify
              )
            "
          ></p-tableCheckbox>
        </td>
        <td>
          <span
            class="expandable-btn"
            *ngIf="data.groupQuestionList?.length"
            type="button"
            pRipple
            (click)="toggleRow(data)"
            (keyDown)="$event.preventDefault()"
          >
            <i
              *ngIf="!expandedRows?.[data.questionBankGroupCode]"
              class="pi pi-chevron-right"
            ></i>
            <i
              *ngIf="expandedRows?.[data.questionBankGroupCode]"
              class="pi pi-chevron-down"
            ></i>
          </span>
        </td>
        <td
          *ngFor="let col of tableColumns | keyvalue : sortInOrder"
          [ngSwitch]="col?.value?.['type']"
        >
          <ng-container *ngSwitchCase="gridDataType.LINK">
            <span *ngIf="!data?.questionBankGroupCode" class="cursor-pointer">
              {{ data[col.key] }}</span
            >
            <span *ngIf="data?.questionBankGroupCode">
              {{ data["questionBankGroupCode"] }}
            </span>
          </ng-container>

          <span
            *ngSwitchCase="gridDataType.RESPONSE_TYPE"
            [escape]="false"
            [pTooltip]="data[col.key]?.length > 25 ? data[col.key] : ''"
            [tooltipPosition]="'left'"
          >
            {{ data["responses"]?.[col.key]}}
          </span>
          <span
            *ngSwitchDefault
            class="ql-editor"
            [pTooltip]="data[col.key]?.length > 25 ? data[col.key] : ''"
            [escape]="false"
            [tooltipPosition]="'left'"
            tooltipStyleClass="ql-editor"
          >
            <span [innerHTML]="data[col.key] | safeHtml"></span>
          </span>
        </td>
      </tr>
      <ng-container *ngIf="expandedRows?.[data.questionBankGroupCode]">
        <ng-container *ngFor="let groupQuestion of data.groupQuestionList">
          <tr class="child-table">
            <td></td>
            <td></td>
            <td
              *ngFor="let col of tableColumns | keyvalue : sortInOrder"
              [ngSwitch]="col.value?.['type']"
            >
              <span *ngSwitchCase="gridDataType.LINK">
                {{ groupQuestion[col.key] || "--" }}</span
              >

              <span
                *ngSwitchCase="gridDataType.RESPONSE_TYPE"
                [pTooltip]="groupQuestion[col.key]"
                [escape]="false"
                [tooltipPosition]="'left'"
              >
                {{ groupQuestion["responses"]?.[col.key] || "--" }}
              </span>
              <span
                class="ql-editor"
                *ngSwitchDefault
                [escape]="false"
                [pTooltip]="
                  groupQuestion[col.key]?.length > 25
                    ? groupQuestion[col.key]
                    : ''
                "
                [tooltipPosition]="'left'"
                tooltipStyleClass="ql-editor"
                [innerHTML]="groupQuestion[col.key] | safeHtml"
              >
              </span>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
      <span class="no-data-found">
        {{ noRecordMsg ? noRecordMsg : "No records found" }}
      </span>
    </ng-template>
  </p-table>
</div>
