import { Injectable } from '@angular/core';
import { Constants } from 'src/app/shared/utilities/constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  loginLogout: BehaviorSubject<string> = new BehaviorSubject<string>('');

  getItem(key: string) {
    const value = localStorage.getItem(key);
    return value ? value : null;
  }

  getAccessToken() {
    const token = this.getItem(Constants.STORAGE_KEYS.ID_TOKEN);
    return token ? token : null;
  }

  setItem(key: string, value: any) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
      return true;
    } catch (e) {
      return false;
    }
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }

  isLoggedIn() {
    return this.getAccessToken() ? true : false;
  }

  isEnabled() {
    try {
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      return true;
    } catch (e) {
      return false;
    }
  }
}
