import { IApiResponse } from 'src/app/shared/interfaces/common-api.interface';

export interface IMasterPlaceholderResponse extends IApiResponse {
  data?: IPlaceholder[];
}

export interface IPlaceholder {
  active: boolean;
  placeholderKey: string;
  placeholderValue: string;
}

export interface IPublishedFormResponse extends IApiResponse {
  data?: IPublishedForm[];
}

export interface IPublishedForm {
  formCode: string;
  formName: string;
}

export interface IUploadedFile {
  name: string;
  fileIndex: number;
}

export interface IUploadEmailResponse extends IApiResponse {
  data?: IUploadedFileURL;
}

export interface IWhatsAppMediaResponse extends IApiResponse {
  data?: IUploadedFileURL;
}

export interface IUploadedFileURL {
  url: string;
}

export enum ChannelType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
}

export enum TemplateMode {
  EDIT = 'Edit',
  VIEW = 'View',
  CREATE = 'New',
  COPY = 'Clone',
}

export enum FileType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
}

export enum MediaDocType {
  STATIC = 'Static',
  DYNAMIC = 'Dynamic',
}

export enum ButtonActionType {
  QUICK_REPLY = 'QUICK_REPLY',
  CALL_BACK = 'CALL_BACK',
  STATIC_CTA = 'STATIC_CTA',
  WEB_FORM_URL = 'WEB_FORM_URL',
  DYNAMIC_CTA = 'DYNAMIC_CTA',
}

export interface ITemplateCreateResponse extends IApiResponse {
  data?: ITemplateSuccessResponse;
}

export interface ITemplateSuccessResponse {
  templateCode: string;
  message: string;
}

export interface ITemplatePayload {
  code?: string;
  name: string;
  channel: string;
  category?: string;
  language: string;
  status: string;
  organizationDetail: {
    organization: string;
    vertical: string;
    division: string;
    department: string;
  };
  whatsAppTemplate?: IWhatsAppPayload;
  smsTemplate?: ISMSPayload;
}

export interface ISMSPayload {
  dltId: string;
  senderId: string;
  principalId: string;
  content: string;
  formCode?: string;
  urlShorten: boolean;
}

export interface IWhatsAppPayload {
  metaId: string;
  template: [
    {
      type: string;
      sequence: string;
      content: string;
      formCode?: string;
      media?: ITemplateMedia;
      button?: IWhatsAppCTAButton[];
    }
  ];
}

export interface ITemplateMedia {
  type: string;
  url: string;
  fileName: string;
}

export interface IWhatsAppCTAButton {
  subType: string;
  sequence: string;
  label: string;
  url: string;
  action: string;
}

export interface IMediaOption {
  label: string;
  value: string;
}

export enum MediaOptionType {
  LINK = 'link',
  ID = 'id',
}
