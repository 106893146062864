<div class="modal-header">
  <h4 class="modal-title pull-left">Select {{ filterType | titlecase }}(s)</h4>
  <button
    type="button"
    class="btn-close close pull-right"
    (click)="confirmExit()"
    aria-label="Close"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="form">
        <ng-select
          id="template"
          formControlName="appliedFilters"
          placeholder="Choose Filters"
          [items]="list"
          [virtualScroll]="true"
          bindLabel="templateCode"
          [clearable]="false"
          [searchable]="true"
          [multiple]="true"
          [closeOnSelect]="false"
          (scrollToEnd)="onScroll()"
        >
          <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <input
              id="item-{{ index }}"
              type="checkbox"
              [checked]="item$.selected"
            />
            {{ item }}
          </ng-template>
        </ng-select>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-footer"
    [ngClass]="{ disabled: form.invalid }"
    [disabled]="form.invalid"
    (click)="applyFilters()"
  >
    Apply
  </button>
</div>
