import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  Elements,
  IBody,
  IFooter,
  IHeader,
} from '../../interfaces/confirm-popup.interface';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss'],
})
export class ConfirmPopupComponent {
  headerConfig: IHeader;
  footerConfig: IFooter;
  bodyConfig: IBody;
  constructor(
    private readonly config: DynamicDialogConfig,
    private readonly dialogRef: DynamicDialogRef
  ) {
    this.headerConfig = config.data?.headerConfig;
    this.footerConfig = config.data?.footerConfig;
    this.bodyConfig = config.data?.bodyConfig;
  }

  isDisabled() {
    let isDisabled = false;
    if (this.bodyConfig.elements?.length) {
      for (let elem of this.bodyConfig.elements) {
        if (elem.isMandatory) {
          if (
            elem.type == 'textarea' &&
            (!elem.response?.length ||
              (elem?.maxCharLength &&
                elem.response &&
                elem.response.length > elem?.maxCharLength) ||
              elem.error)
          ) {
            return true;
          }
          if (elem.type == 'mobile_number') {
            let res = elem.response;
            if (res != null && res != undefined) {
              res = res.toString();
              if (res.length != elem.length || res[0].startsWith('0')) {
                return true;
              }
            } else {
              return true;
            }
          }
        }
      }
    }
    return isDisabled;
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onConfirmClick() {
    this.dialogRef.close({
      data: this.bodyConfig.elements,
    });
  }

  typeMobileNo(event: any) {
    if (event.key === 'e' || event.key === 'E') {
      event.preventDefault();
    }
  }

  onInput($event: any) {
    if ($event.target.value != undefined) {
      $event.target.value = $event.target.value.replace(/^0+/, '');
    }
  }

  onChangeTextArea(elem: Elements) {
    const regex = /^[A-Za-z0-9()+.&\\\-\/,?*' :;]+$/;
    if (elem.response) {
      if (regex.test(elem.response)) {
        elem.error = false;
      } else {
        elem.error = true;
      }
    } else {
      elem.error = false;
    }
  }
}
