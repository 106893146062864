import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-data-list',
  templateUrl: './data-list.component.html',
  styleUrls: ['./data-list.component.scss'],
})
export class DataListComponent {
  itemList: any[] = [];
  selectedItem: any;

  constructor(
    private readonly dialogConfig: DynamicDialogConfig,
    private readonly dynamicDialogRef: DynamicDialogRef
  ) {
    this.itemList = this.dialogConfig.data.itemList;
  }

  onClickImport() {
    this.dynamicDialogRef.close(this.selectedItem);
  }

  onCancel() {
    this.dynamicDialogRef.close();
  }
}
