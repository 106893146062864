export const Constants = {
  STORAGE_KEYS: {
    USER_DATA: 'userData',
    ID_TOKEN: 'idToken',
  },
  DAYS_LIST: [
    2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
    23, 24, 25, 26, 27, 28, 29, 30, 31,
  ],
  MONTHS_LIST: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  DAYS_NAME_LIST: [
    {
      key: 'SUN',
      label: 'Sunday',
    },
    {
      key: 'MON',
      label: 'Monday',
    },
    {
      key: 'TUE',
      label: 'Tuesday',
    },
    {
      key: 'WED',
      label: 'Wednesday',
    },
    {
      key: 'THU',
      label: 'Thursday',
    },
    {
      key: 'FRI',
      label: 'Friday',
    },
    {
      key: 'SAT',
      label: 'Saturday',
    },
  ],
  WEEK_NUM_LIST: [
    {
      key: '#1',
      label: 'First',
    },
    {
      key: '#2',
      label: 'Second',
    },
    {
      key: '#3',
      label: 'Third',
    },
    {
      key: '#4',
      label: 'Fourth',
    },
    {
      key: '#5',
      label: 'Fifth',
    },
    {
      key: 'L',
      label: 'Last',
    },
  ],
  MONTHS_NAME_LIST: [
    {
      key: 1,
      label: 'January',
    },
    {
      key: 2,
      label: 'February',
    },
    {
      key: 3,
      label: 'March',
    },
    {
      key: 4,
      label: 'April',
    },
    {
      key: 5,
      label: 'May',
    },
    {
      key: 6,
      label: 'June',
    },
    {
      key: 7,
      label: 'July',
    },
    {
      key: 8,
      label: 'August',
    },
    {
      key: 9,
      label: 'September',
    },
    {
      key: 10,
      label: 'October',
    },
    {
      key: 11,
      label: 'November',
    },
    {
      key: 12,
      label: 'December',
    },
  ],
  TAB_NAMES: [
    {
      id: 'Daily',
      label: 'Daily',
    },
    {
      id: 'Weekly',
      label: 'Weekly',
    },
    {
      id: 'Monthly',
      label: 'Monthly',
    },
    {
      id: 'Yearly',
      label: 'Yearly',
    },
  ],
};
