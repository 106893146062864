import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Table } from 'primeng/table';
import { GRID_DATA_TYPE } from 'src/app/core/constants/common';
import { DataRow } from '../../interfaces/tablelist-request.interface';

@Component({
  selector: 'app-common-data-grid',
  templateUrl: './common-data-grid.component.html',
  styleUrls: ['./common-data-grid.component.scss'],
})
export class CommonDataGridComponent {
  @ViewChild('commonDataGridTable') commonDataGridTable!: Table;

  tableData: any[] = [];
  selectedRows: any[] = [];

  @Input() tableColumns: { [key: string]: any } = {};
  @Input() pagination = false;
  @Input() noRecordMsg = '';
  @Input() resizableColumns = false;
  @Input() targetFormDetails: any;
  @Input() isBankGroupQuesAvailable: boolean;
  @Input() expandedRows: { [key: string]: boolean } = {};

  @Output() CellDataClick = new EventEmitter<DataRow[]>();

  public gridDataType = GRID_DATA_TYPE;
  pageSize = 5;

  @Input()
  set data(val: DataRow[]) {
    if (val?.length > 0) {
      this.tableData = val;
      this.selectedRows = this.tableData.filter(
        (data: DataRow) => data.isSelected
      );
      this.redirectToFirstPage();
    } else {
      this.tableData = [];
      this.selectedRows = [];
    }
  }

  redirectToFirstPage() {
    if (this.commonDataGridTable) {
      this.commonDataGridTable.first = 0;
    }
  }

  sortInOrder() {
    return 0;
  }

  onSelectRow() {
    this.selectedRows.sort(
      (a, b) => (a.questionSeq ?? 0) - (b.questionSeq ?? 0)
    );

    this.selectedRows.forEach((item) => {
      item.isSelected = this.checkVisiblity(
        item?.questionBankGroupCode,
        item?.isSourceAutoQualify
      );
    });
    this.selectedRows = this.selectedRows.filter((row) => row.isSelected);
    this.CellDataClick.emit(this.selectedRows);
  }

  toggleRow(data: DataRow): void {
    this.expandedRows[data.questionBankGroupCode ?? ''] =
      !this.expandedRows[data.questionBankGroupCode ?? ''];
  }

  checkVisiblity(
    sourceGroupQuestion: string,
    isSourceAutoQualify: boolean
  ): boolean {
    const isTargetStandalone =
      this.targetFormDetails?.targetFormType?.toLowerCase() === 'stand alone';
    const isTargetLQSIntegrated =
      this.targetFormDetails?.targetFormType?.toLowerCase() ===
      'api integrated';
    if (isTargetStandalone) {
      return !sourceGroupQuestion;
    }
    if (isTargetLQSIntegrated && sourceGroupQuestion) {
      if (
        !this.isBankGroupQuesAvailable &&
        isSourceAutoQualify === this.targetFormDetails?.isTargetAutoQualify
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
