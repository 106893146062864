<div class="preview-container" *ngIf="channelTypeImg">
  <div class="close" (click)="bsModalRef.hide()">
    <img src="{{ icons.CLOSE }}" alt="Close" />
  </div>
  <h2>{{ title }}</h2>
  <div class="preview-modal-body">
    <div class="subject-email" *ngIf="emailSubject">
      {{ emailSubject }}
    </div>
    <div
      class="content"
      [ngClass]="{ 'email-content': channelType === previewCase.EMAIL }"
      *ngIf="!isCarousel"
    >
      <ng-container *ngIf="mediaURL">
        <img
          class="preview-media image"
          [src]="mediaURL"
          *ngIf="mediaFileType === mediaType.IMAGE"
          alt="Preview"
        />
        <video
          controls
          controlsList="nodownload"
          class="preview-media video"
          *ngIf="mediaFileType === mediaType.VIDEO"
        >
          <source [src]="mediaURL" type="video/mp4" />
          <track
            [src]="mediaURL"
            kind="subtitles"
            srclang="en"
            label="English"
          />
        </video>
      </ng-container>
      <p>{{ content }}</p>
      <p *ngIf="footer" class="footer-color">{{ footer }}</p>
    </div>
    <ng-container *ngIf="ctaButtons?.length && !isCarousel">
      <ng-container *ngFor="let button of ctaButtons; let i = index">
        <div
          class="text-center"
          *ngIf="i < 3"
          [ngClass]="{
            'cta-button-1': i === 0,
            'cta-button-2': i === 1,
            'cta-button-3': i === 2
          }"
        >
          <img
            src="{{
              button.buttonAction === 'Quick Reply'
                ? icons.REPLY
                : icons.OPEN_URL
            }}"
            *ngIf="i < 2 && button.buttonAction !== 'Call Back'"
            alt="URL"
          />
          <img
            src="{{
              button.buttonAction === 'Quick Reply'
                ? icons.REPLY
                : icons.OPEN_URL
            }}"
            *ngIf="
              i === 2 &&
              button.buttonAction !== 'Call Back' &&
              ctaButtons &&
              ctaButtons.length <= 3
            "
            alt="URL"
          />
          <span
            class="option-class"
            *ngIf="
              i === 2 &&
              button.buttonAction !== 'Call Back' &&
              ctaButtons &&
              ctaButtons.length > 3
            "
          >
            <i class="bi bi-list-ul alloptions-icon"></i>
          </span>
          <i
            class="bi bi-telephone-inbound-fill callback-icon"
            *ngIf="button.buttonAction === 'Call Back'"
          ></i>
          <span>{{
            i < 2
              ? button.label
              : i === 2 && ctaButtons && ctaButtons.length <= 3
              ? button.label
              : "See all options"
          }}</span>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isCarousel">
      <div class="preview-carousel">
        <div class="cards">
          <p *ngIf="content">
            {{ content }}
          </p>
        </div>
        <div class="carousel-cards d-flex">
          <div
            *ngFor="let section of carouselCards; let i = index"
            class="cards"
          >
            <ng-container
              *ngIf="section.mediaURL && section.mediaURL !== '#mediaUrl#'"
            >
              <img
                class="preview-media image"
                [src]="section.mediaURL"
                *ngIf="section.mediaType?.toUpperCase() === mediaType.IMAGE"
                alt="Preview"
              />
              <video
                controls
                controlsList="nodownload"
                class="preview-media video"
                *ngIf="section.mediaType?.toUpperCase() === mediaType.VIDEO"
              >
                <source [src]="section.mediaURL" type="video/mp4" />
                <track
                  [src]="section.mediaURL"
                  kind="subtitles"
                  srclang="en"
                  label="English"
                />
              </video>
              <p *ngIf="section.content" class="card-text">
                {{ section.content }}
              </p>
            </ng-container>
            <ng-container *ngIf="section.buttons.length">
              <ng-container
                *ngFor="let button of section.buttons; let j = index"
              >
                <div *ngIf="button.label" class="d-flex justify-content-center">
                  <img
                    src="{{ icons.OPEN_URL }}"
                    *ngIf="
                      button.buttonAction?.toLowerCase() === 'static cta' ||
                      button.buttonAction?.toLowerCase() === 'dynamic cta'
                    "
                    alt="URL"
                  />
                  <img
                    src="{{ icons.REPLY }}"
                    *ngIf="button.buttonAction?.toLowerCase() === 'quick reply'"
                    alt="QUICK_REPLY"
                  />
                  <i
                    class="bi bi-telephone-inbound-fill callback-icon"
                    *ngIf="button.buttonAction?.toLowerCase() === 'callback'"
                  ></i>
                  <span class="carousel-button-label">{{ button.label }}</span>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <img src="{{ channelTypeImg }}" alt="Channel_Type" />
  </div>
  <div class="preview-modal-footer">
    <button class="btn-back" (click)="bsModalRef.hide()">
      Back to Templates
    </button>
  </div>
</div>
