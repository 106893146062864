<div class="wrapper sidebar-collapse">
  <div class="sidebar">
    <div class="sb-item-list">
      <p-scrollPanel>
        <div *ngFor="let sideMenuItem of sideMenuList">
          <ng-container *appElementPermissible="sideMenuItem.permission">
            <div *ngIf="!sideMenuItem.children">
              <div
                class="sb-item"
                [ngClass]="{
                  active: currentURL === sideMenuItem.route
                }"
                [routerLink]="[sideMenuItem.route]"
                (click)="subOptions(sideMenuItem, false)"
              >
                <img
                  class="menu-icon unlock"
                  [src]="
                    currentURL === sideMenuItem.route
                      ? sideMenuItem.activeIcon
                      : sideMenuItem.inActiveIcon
                  "
                  [alt]="sideMenuItem.name"
                />
                <img
                  class="menu-icon lock"
                  [src]="sideMenuItem.activeIcon"
                  [alt]="sideMenuItem.name"
                />
                <span class="navtext">{{ sideMenuItem.name }}</span>
              </div>
            </div>
            <div *ngIf="sideMenuItem.children">
              <div
                class="sb-item"
                [ngClass]="{
                  active: currentURL === sideMenuItem.route
                }"
                id="form_menu"
                (click)="subOptions(sideMenuItem, true, true)"
              >
                <img
                  class="menu-icon unlock"
                  [src]="
                    currentURL === sideMenuItem.route
                      ? sideMenuItem.activeIcon
                      : sideMenuItem.inActiveIcon
                  "
                  [alt]="sideMenuItem.name"
                />
                <img
                  class="menu-icon lock"
                  [src]="sideMenuItem.activeIcon"
                  [alt]="sideMenuItem.name"
                />
                <span class="navtext">{{ sideMenuItem.name }}</span>
              </div>
              <div *ngIf="sideMenuItem.showChildren">
                <div
                  class="submenu"
                  *ngFor="let children of sideMenuItem?.children"
                >
                  <ng-container *appElementPermissible="children.permission">
                    <div
                      class="sub-item"
                      [routerLinkActive]="'active'"
                      [routerLink]="
                        children.route
                          ? [sideMenuItem.route, children.route]
                          : null
                      "
                      (click)="subOptions(sideMenuItem, true)"
                    >
                      {{ children.name }}
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </p-scrollPanel>
    </div>
  </div>
</div>
