import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { LoginComponent } from './components/login/login.component';
import { ExitModalComponent } from './components/exit-modal/exit-modal.component';
import { SharedModule } from '../shared/shared.module';
import { ConsoleErrorViewService } from './console-error-view/console.error.view.service';
import { ConfirmationService } from 'primeng/api';
import { IsElementPermissibleDirective } from './directives/is-element-permissible/is-element-permissible.directive';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    ClickOutsideDirective,
    LoginComponent,
    ExitModalComponent,
    IsElementPermissibleDirective,
  ],
  imports: [CommonModule, HttpClientModule, RouterModule, SharedModule],
  exports: [HttpClientModule, HeaderComponent, SidebarComponent],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ConsoleErrorViewService,
    },
    ConfirmationService,
  ],
})
export class CoreModule {}
