export const MIME_TYPE = {
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
  PDF: 'application/pdf',
  EML: 'message/rfc822',
  CSV: 'text/csv',
  MP4: 'video/mp4',
  DOC: 'application/msword',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
