import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/modules/configuration/services/configuration.service';
import { FORM_STATUS } from 'src/app/modules/form-design/constants/forms-listing.constants';
import { SegmentStatus } from 'src/app/modules/segments/interfaces/segment-list-response.interface';
import * as moment from 'moment';
import { SchedulerStatus } from 'src/app/modules/campaign-scheduler/interfaces/scheduler-listing-response.interface';
import { CampaignStatus } from 'src/app/modules/campaigns/interfaces/campaign-list-response.interface';
import { ITemplateFilteredData } from 'src/app/modules/templates/interfaces/template-list-response.interface';
import { TemplateStatus } from 'src/app/core/constants/common';
@Injectable({
  providedIn: 'root',
})
export class GridService {
  constructor(
    private readonly http: HttpClient,
    private readonly configService: ConfigurationService
  ) {}

  getGridData(url: string, payload: any) {
    return this.http.post(url, payload);
  }

  getGridData1(url: string, payload: any) {
    return this.http.post(url, payload);
  }

  processWabaListing(data: any[]) {
    const wabaMasterConfig = this.configService.getWabaMasterMap();
    return this.prePareWabaListData(
      data,
      wabaMasterConfig.bspMap,
      wabaMasterConfig.wabaTypeMap
    );
  }

  prePareWabaListData(
    data: any[],
    bspMap: { [key: string]: string },
    wabaTypeMap: { [key: string]: string | { [key: string]: string } }
  ) {
    const subTypeMap: { [key: string]: string } = wabaTypeMap[
      'wabaSubTypeMap'
    ] as { [key: string]: string };
    for (const obj of data) {
      obj.bspStr = bspMap[obj.bsp] ? bspMap[obj.bsp] : obj.bsp;
      obj.typeStr = wabaTypeMap[obj.type] ? wabaTypeMap[obj.type] : obj.type;
      obj.subTypeStr =
        obj.subType && subTypeMap[obj.subType]
          ? subTypeMap[obj.subType]
          : obj.subType;

      if (obj.statusReason) {
        let tooltipText;
        if (obj.active) {
          tooltipText = `<b>Reason of Reactivation</b> <br> <span> ${obj.statusReason}</span>`;
        } else {
          tooltipText = `<b>Reason of Deactivation</b> <br> <span> ${obj.statusReason}</span>`;
        }
        obj.statusInfo = tooltipText;
      }
      if (obj.active) {
        obj.statusStr = 'Active';
        obj.isTestView = true;
        obj.statusColClasses = ' executed-status ';
        obj.isCopyView = true;
        if (!obj.defaultStatus) {
          obj.isDeactivateView = true;
        }
      } else {
        obj.statusStr = 'Inactive';
        obj.isReactivateView = true;
        obj.statusColClasses = ' failed-text ';
      }
    }
    return data;
  }

  processFormListing(data: any[]) {
    for (const obj of data) {
      if (obj.reason) {
        obj.statusInfo = `<b>Reason of De-Comission</b> <br> <span> ${obj.reason}</span>`;
      }
      obj.isPreviewView = true;
      if (obj.formStatus === FORM_STATUS.DECOMMISIONED) {
        obj.statusColClasses = ' failed-text ';
      } else if (obj.formStatus === FORM_STATUS.PUBLISHED) {
        obj.isDecommissionedView = true;
        obj.isCopyView = true;
        obj.statusColClasses = ' publish-status ';
      } else if (obj.formStatus === FORM_STATUS.DRAFT) {
        obj.isConfigureView = true;
        obj.isDeleteView = true;
        obj.statusColClasses = ' draft-status ';
      } else if (obj.formStatus === FORM_STATUS.INPROGRESS) {
        obj.statusColClasses = ' inprogress-status ';
      }
    }
    return data;
  }

  processSegmentListing(data: any[]) {
    for (const obj of data) {
      switch (obj.status) {
        case SegmentStatus.CREATED:
          obj.statusColClasses = ' created-status ';
          break;
        case SegmentStatus.EXECUTED:
          obj.statusColClasses = ' executed-status ';
          break;
        case SegmentStatus.FAILED:
          obj.statusColClasses = ' failed-text ';
          break;
        case SegmentStatus.IN_PROGRESS:
          obj.statusColClasses = ' inprogress-status ';
          break;
        case SegmentStatus.SCHEDULED:
          obj.statusColClasses = ' scheduled-status ';
          break;
        case SegmentStatus.MAPPED:
          obj.statusColClasses = ' blue-text ';
          break;
        case SegmentStatus.SCRUBBING:
        case SegmentStatus.TRANSFORMING:
        case SegmentStatus.EXTRACTING:
          obj.statusColClasses = ' dark-blue-text ';
          break;
        case SegmentStatus.EXTRACTED:
          obj.statusColClasses = ' green-text ';
          break;
        case SegmentStatus.SCRUBBED:
          obj.statusColClasses = ' light-green-text ';
          break;
        case SegmentStatus.CAMPAIGN_RESCHEDULED:
          obj.statusColClasses = ' orange-text ';
          break;
        default:
          break;
      }
    }
    return data;
  }

  processSchedulerListing(data: any[]) {
    for (const obj of data) {
      if (obj.campaignStartDate) {
        obj.campaignStartDateStr = moment(
          obj.campaignStartDate,
          'DD-MM-YYYY'
        ).format('DD MMM YYYY');
      }
      if (obj.campaignEndDate) {
        obj.campaignEndDateStr = moment(
          obj.campaignEndDate,
          'DD-MM-YYYY'
        ).format('DD MMM YYYY');
      }
      if (obj.executionStartDate) {
        obj.executionStartDateStr = moment(
          obj.executionStartDate,
          'DD-MM-YYYY HH:mm'
        ).format('DD MMM YYYY HH:mm');
      }
      if (obj.lastExecutedOn) {
        obj.lastExecutedOnStr = moment(obj.lastExecutedOn, 'DD-MM-YYYY').format(
          'DD MMM YYYY'
        );
      }
      if (obj.status === SchedulerStatus.IN_PROGRESS) {
        obj.statusColClasses = ' inprogress-status ';
      } else if (obj.status === SchedulerStatus.EXECUTED) {
        obj.statusColClasses = ' executed-status ';
      } else if (
        obj.status === SchedulerStatus.RESCHEDULED ||
        obj.status === SchedulerStatus.SCHEDULED
      ) {
        obj.statusColClasses = ' scheduled-status ';
      } else if (
        obj.status === SchedulerStatus.RESCHEDULING_FAILED ||
        obj.status === SchedulerStatus.FAILED
      ) {
        obj.statusColClasses = ' failed-text ';
      }
    }
    return data;
  }

  processCampaignListing(data: any[]) {
    for (const obj of data) {
      if (obj.startDate) {
        obj.startDateStr = moment(obj.startDate, 'DD-MM-YYYY').format(
          'DD MMM YYYY'
        );
      }
      if (obj.endDate) {
        obj.endDateStr = moment(obj.endDate, 'DD-MM-YYYY').format(
          'DD MMM YYYY'
        );
      }
      if (obj.status === CampaignStatus.DRAFT) {
        obj.statusColClasses = ' inprogress-status ';
      } else if (obj.status === CampaignStatus.PUBLISHED) {
        obj.statusColClasses = ' executed-status ';
      }
    }
    return data;
  }

  processFileUploadListing(data: any[]) {
    for (const obj of data) {
      if (obj.communicationChannel === 'WHATSAPP') {
        obj.channel = 'WhatsApp';
        obj.channelIconSrc = 'preview/whatsapp_icon.svg';
      } else if (obj.communicationChannel === 'SMS') {
        obj.channel = 'SMS';
        obj.channelIconSrc = 'preview/sms_icon.svg';
      }
    }
    return data;
  }

  processTemplateListing(filteredResponse: ITemplateFilteredData[]) {
    filteredResponse.forEach((rowData: ITemplateFilteredData) => {
      rowData.isEditView = false;
      rowData.isPreviewView = false;
      rowData.isRetireView = false;
      rowData.isCopyView = false;
      rowData.isReactivateView = false;
      rowData.statusColClasses = '';
      const rowDataStatus = rowData.status?.toLowerCase();
      const rowDataChannel = rowData.channel?.toLowerCase();
      if (rowData?.statusReason) {
        let tooltipText = '';
        if (rowDataStatus === TemplateStatus.RETIRED.toLowerCase()) {
          tooltipText = 'Retiring';
        } else if (rowDataStatus === TemplateStatus.PUBLISHED.toLowerCase()) {
          tooltipText = 'Reinstating';
        }
        tooltipText = `<b>Reason of ${tooltipText} template </b> <br> <span> ${rowData?.statusReason}</span>`;
        rowData.statusInfo = tooltipText;
      }

      if (rowDataStatus === TemplateStatus.PUBLISHED.toLowerCase()) {
        rowData.isEditView = rowDataChannel === 'sms' ? false : true;
        rowData.isPreviewView = true;
        rowData.isRetireView = true;
        rowData.isCopyView = true;
        rowData.statusColClasses = ' executed-status ';
      } else if (rowDataStatus === TemplateStatus.PENDING.toLowerCase()) {
        rowData.isPreviewView = true;
        rowData.isCopyView = true;
        rowData.statusColClasses = ' inprogress-status ';
      } else if (rowDataStatus === TemplateStatus.DRAFT.toLowerCase()) {
        rowData.isDeleteView = true;
        rowData.isEditView = true;
        rowData.isPreviewView = true;
        rowData.statusColClasses = ' draft-status ';
      } else if (rowDataStatus === TemplateStatus.RETIRED.toLowerCase()) {
        rowData.isCopyView = true;
        rowData.isReactivateView = true;
        rowData.isPreviewView = true;
        rowData.statusColClasses = ' failed-text ';
      } else if (rowDataStatus === TemplateStatus.REJECTED.toLowerCase()) {
        rowData.isPreviewView = true;
        rowData.isEditView = rowDataChannel === 'sms' ? false : true;
        rowData.isCopyView = true;
        rowData.statusColClasses = ' failed-text ';
      }
    });
    return filteredResponse;
  }
}
