import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { IModal, ModalButtonType } from '../../interfaces/modal.interface';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ChannelType } from 'src/app/modules/campaign-scheduler/interfaces/schedule-campaign.interface';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SchedulerTestService } from '../../services/scheduler-test.service';
import {
  ISchedulerTestData,
  ITestRealTimeResponse,
} from '../../interfaces/scheduler-test.interface';
import { MessageService } from 'primeng/api';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-scheduler-test-modal',
  templateUrl: './scheduler-test-modal.component.html',
  styleUrls: ['./scheduler-test-modal.component.scss'],
})
export class SchedulerTestModalComponent implements OnInit, AfterViewInit {
  @Input() config: IModal;
  @Input() campaignCode: string;
  @Input() enablerForm: AbstractControl;
  @Input() placeholders: string[];

  modalButtonType = ModalButtonType;
  testSchedulerForm: FormGroup;
  channelType = ChannelType;
  selectedChannelType: string;

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
    private messageService: MessageService,
    private commonService: CommonService,
    private schedulerTestService: SchedulerTestService
  ) {}

  ngOnInit(): void {
    this.testSchedulerForm = this.fb.group({});
    this.selectedChannelType = this.enablerForm.get('enabler')?.value;
    if (this.selectedChannelType === ChannelType.EMAIL) {
      this.addEmailControl();
    } else {
      this.addPhoneNumberControl();
    }
    this.addPlaceholdersControl();
  }

  ngAfterViewInit(): void {
    document
      .getElementsByClassName('modal-dialog')[0]
      ?.classList.add('modal-dialog-centered');
  }

  get form(): { [key: string]: AbstractControl } {
    return this.testSchedulerForm?.controls;
  }

  addPhoneNumberControl(): void {
    this.testSchedulerForm.addControl(
      'phoneNumber',
      new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.pattern('^[0-9]{0,10}$'),
      ])
    );
  }

  addEmailControl(): void {
    this.testSchedulerForm.addControl(
      'email',
      new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.minLength(6),
        Validators.maxLength(50),
      ])
    );
  }

  addPlaceholdersControl(): void {
    if (this.placeholders?.length) {
      this.placeholders.forEach((placeholder) => {
        this.testSchedulerForm.addControl(
          placeholder,
          new FormControl('', [Validators.required])
        );
      });
    }
  }

  sendRealTimeInfo(form: FormGroup): void {
    const payload = this.preparePayload(form);
    this.schedulerTestService
      .sendRealTimeInfo(this.selectedChannelType.toLowerCase(), payload)
      .subscribe((response: ITestRealTimeResponse) => {
        this.close();
        this.commonService.showSuccessMessage(response.message);
      });
  }

  preparePayload(form: FormGroup): ISchedulerTestData {
    let placeholderData = {};
    const formData = form.value;
    const enablerFormData = this.enablerForm.value;
    const recipientId =
      enablerFormData.enabler !== this.channelType.EMAIL
        ? `91${form.get('phoneNumber')?.value}`
        : form.get('email')?.value;

    if (enablerFormData.enabler === this.channelType.WHATSAPP) {
      delete formData.phoneNumber;
      for (let key of Object.keys(formData)) {
        let idx = this.placeholders.findIndex(
          (placeholder) => placeholder === key
        );
        placeholderData = {
          ...placeholderData,
          ...{ [idx + 1]: formData[key] },
        };
      }
    } else {
      delete formData.email;
      placeholderData = formData;
    }

    return {
      campaignCode: this.campaignCode,
      templateData: [
        {
          templateId: enablerFormData.templateId,
          data: [
            {
              recipientId: recipientId,
              placeholders: placeholderData,
            },
          ],
        },
      ],
    };
  }

  close(): void {
    this.modalService.hide();
  }
}
