<div class="login-container">
  <div class="inner-box">
    <div class="logo-section">
      <img
        src="assets/ms_login_logo.svg"
        alt="Maruti Suzuki"
        id="maruti-suzuki-login-icon"
      />
      <h3>Communication Manager System</h3>
    </div>
    <div class="pt-2 pb-2 d-flex justify-content-center">
      <button
        type="button"
        class="btn w-50"
        id="submit-btn"
        (click)="onLoginClick()"
      >
        Log In
      </button>
    </div>
  </div>
</div>
