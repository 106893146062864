import { Component, Input, OnChanges } from '@angular/core';
import { Constants } from '../../utilities/constants';
import cronstrue from 'cronstrue';

@Component({
  selector: 'app-recurrence-pattern',
  templateUrl: './recurrence-pattern.component.html',
  styleUrls: ['./recurrence-pattern.component.scss'],
})
export class RecurrencePatternComponent implements OnChanges {
  @Input() startTimeDate: Date;
  daysList = Constants.DAYS_LIST;
  monthList = Constants.MONTHS_LIST;
  monthlyDaysList: number[] = [];
  weekList: number[] = [];
  daysNameList = [
    {
      key: 'SUN',
      label: 'Sunday',
      isSelected: false,
    },
    {
      key: 'MON',
      label: 'Monday',
      isSelected: false,
    },
    {
      key: 'TUE',
      label: 'Tuesday',
      isSelected: false,
    },
    {
      key: 'WED',
      label: 'Wednesday',
      isSelected: false,
    },
    {
      key: 'THU',
      label: 'Thursday',
      isSelected: false,
    },
    {
      key: 'FRI',
      label: 'Friday',
      isSelected: false,
    },
    {
      key: 'SAT',
      label: 'Saturday',
      isSelected: false,
    },
  ];

  weekDayNameList = Constants.DAYS_NAME_LIST;
  weekDayList = Constants.WEEK_NUM_LIST;
  tabList = Constants.TAB_NAMES;
  monthNameList = Constants.MONTHS_NAME_LIST;

  tabMap = {
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    YEARLY: 'Yearly',
  };

  dailyOptionMap = {
    EVERY_N_DAY: 'EVERY_N_DAY',
    EVERYDAY: 'EVERYDAY',
  };

  monthlyOptionMap = {
    DAY: 'DAY',
    WEEKDAY: 'WEEKDAY',
  };

  yearlyOptionMap = {
    DAY: 'DAY',
    WEEKDAY: 'WEEKDAY',
  };

  activeTab = this.tabList[0];
  selectedDailyTab = this.dailyOptionMap.EVERY_N_DAY;
  selectedMonthlyTab = this.monthlyOptionMap.DAY;
  selectedYearlyTab = this.yearlyOptionMap.DAY;
  selectedDayInterval: number;
  selectedWeeklyRecurInterval: number;
  selectedYearlyRecurInterval: number;
  cronExpression: string;
  cronDescription: string;
  secondsMinutesHoursStr = '0 0 10';

  monthlyDayCriteria = {
    day: 1,
    month: 1,
  };

  monthlyWeekDayCriteria = {
    week: '#1',
    day: 1,
    month: 1,
  };

  yearlyDayCriteria = {
    month: 1,
    day: 1,
  };

  yearlyWeekDayCriteria = {
    week: '#1',
    day: 'MON',
    month: 1,
  };

  constructor() {
    for (let i = 1; i <= 52; i++) {
      this.weekList.push(i);
    }
    this.monthlyDaysList = [...this.daysList];
    this.monthlyDaysList.unshift(1);
  }

  ngOnChanges() {
    if (this.startTimeDate) {
      this.secondsMinutesHoursStr = `0 ${this.startTimeDate.getUTCMinutes()} ${this.startTimeDate.getUTCHours()}`;
    }
  }

  onActiveItemChange(event: any): void {
    this.activeTab = event;
  }

  createDailyCron(): void {
    if (this.selectedDailyTab === this.dailyOptionMap.EVERY_N_DAY) {
      this.cronExpression = `${this.secondsMinutesHoursStr} */${this.selectedDayInterval} * ?`;
    } else if (this.selectedDailyTab === this.dailyOptionMap.EVERYDAY) {
      this.cronExpression = `${this.secondsMinutesHoursStr} * * ?`;
    }
  }

  createWeeklyCron(): void {
    const selectedDays: string[] = [];

    if (this.daysNameList.length) {
      for (const day of this.daysNameList) {
        if (day.isSelected) {
          selectedDays.push(day.key);
        }
      }
      if (selectedDays.length != 7 && selectedDays.length != 0) {
        this.cronExpression = `${
          this.secondsMinutesHoursStr
        } ? * ${selectedDays.join()}`;
      } else {
        this.cronExpression = `${this.secondsMinutesHoursStr} * * ?`;
      }
    }
  }

  createMonthlyCron(): void {
    if (this.selectedMonthlyTab === this.monthlyOptionMap.DAY) {
      this.cronExpression = `${this.secondsMinutesHoursStr} ${this.monthlyDayCriteria.day} * ?`;
    } else if (this.selectedMonthlyTab === this.monthlyOptionMap.WEEKDAY) {
      this.cronExpression = `${this.secondsMinutesHoursStr} ? * ${this.monthlyWeekDayCriteria.day}${this.monthlyWeekDayCriteria.week}`;
    }
  }

  createYearlyCron(): void {
    if (this.selectedYearlyTab === this.yearlyOptionMap.DAY) {
      this.cronExpression = `${this.secondsMinutesHoursStr} ${this.yearlyDayCriteria.day} ${this.yearlyDayCriteria.month} ?`;
    } else if (this.selectedYearlyTab === this.yearlyOptionMap.WEEKDAY) {
      this.cronExpression = `${this.secondsMinutesHoursStr} ? ${this.yearlyWeekDayCriteria.month} ${this.yearlyWeekDayCriteria.day}${this.yearlyWeekDayCriteria.week}`;
    }
  }

  getCronExpressionAndDescription() {
    switch (this.activeTab.id) {
      case this.tabMap.DAILY:
        this.createDailyCron();
        break;
      case this.tabMap.WEEKLY:
        this.createWeeklyCron();
        break;
      case this.tabMap.MONTHLY:
        this.createMonthlyCron();
        break;
      case this.tabMap.YEARLY:
        this.createYearlyCron();
        break;
      default:
        break;
    }
    if (this.cronExpression) {
      this.cronDescription = cronstrue.toString(this.cronExpression);
    }
    return {
      cronExpression: this.cronExpression,
      cronDescription: this.cronDescription,
      frequency: this.activeTab.id,
    };
  }
}
