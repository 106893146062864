<div class="select-channel-dialog">
  <div class="chips-parent">
    <div
      *ngFor="let channel of channels"
      (click)="channelSelected(channel)"
      class="chips"
      (keyDown)="$event.preventDefault()"
    >
      <div>
        <div class="img" *ngIf="channel.icon">
          <img
            src="{{ channel.icon }}"
            class="icons-img"
            [alt]="channel.label"
          />
        </div>
        <div>{{ channel.label }}</div>
      </div>
    </div>
  </div>
</div>
