<div class="modal-header">
  <i class="bi bi-exclamation-triangle"></i>
  <h4 class="modal-title pull-left">You have unsaved changes!</h4>
  <button
    type="button"
    class="btn-close close pull-right"
    aria-label="Close"
    (click)="close()"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    {{ warningMessage }}
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-footer" (click)="confirmExit()">
    Exit Without Saving
  </button>
  <button
    type="button"
    class="btn btn-footer"
    (click)="stayOrSaveAsDraft()"
    [ngClass]="{
      disabled: isDisabled()
    }"
    [disabled]="isDisabled()"
  >
    {{ cancelButtonText }}
  </button>
</div>
