import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private permissions: string[] = [];

  setPermission(permissions: Array<any>) {
    this.permissions = permissions.map((data: any /**NOSONAR */) => data.key);
  }

  getPermission() {
    return this.permissions;
  }

  checkPermission(value: string): boolean {
    if (!value) {
      return true;
    } else {
      if (this.getPermission()?.includes(value)) {
        return true;
      } else {
        return false;
      }
    }
  }
}
