import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL } from 'src/app/core/constants/api';
import { environment } from 'src/environments/environment';
import {
  ISchedulerTestData,
  ITestRealTimeResponse,
} from '../interfaces/scheduler-test.interface';
import { Observable } from 'rxjs';

@Injectable()
export class SchedulerTestService {
  constructor(private http: HttpClient) {}

  sendRealTimeInfo(
    channel: string,
    payload: ISchedulerTestData
  ): Observable<ITestRealTimeResponse> {
    return this.http.post<ITestRealTimeResponse>(
      `${environment.COMM_BASE_URL}${API_URL.SEND_REAL_TIME}/${channel}`,
      payload,
      { headers: { businessDivision: 'CMGR' } }
    );
  }
}
