<div class="modal-header">
  <h4 class="modal-title pull-left">Select Date Filter</h4>
  <button
    type="button"
    class="btn-close close pull-right"
    (click)="confirmExit()"
    aria-label="Close"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="form">
        <div class="form-content">
          <span class="input-group-text start-date border-0">
            <img src="{{ icons.CALENDER }}" height="21" alt="Calendar" />
          </span>
          <input
            class="form-control form-control-sm bs-date-input"
            #datepicker="bsDatepicker"
            bsDatepicker
            placeholder="DD/MM/YYYY"
            formControlName="date"
            [bsConfig]="datepickerConfig"
          />
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-footer"
    [ngClass]="{ disabled: form.invalid }"
    [disabled]="form.invalid"
    (click)="applyFilters()"
  >
    Apply
  </button>
</div>
