import { AfterViewInit, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ModalButtonType } from 'src/app/shared/interfaces/modal.interface';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-exit-modal',
  templateUrl: './exit-modal.component.html',
  styleUrls: ['./exit-modal.component.scss'],
})
export class ExitModalComponent implements AfterViewInit {
  form?: FormGroup;
  checkFormValidation?: boolean;
  modalButtonType = ModalButtonType;
  cancelButtonText = 'Stay On This Page';
  warningMessage = `You're about to discard the #form# creation process. Would you like to save the
  information before leaving?`;
  result: Subject<boolean> = new Subject<boolean>();

  constructor(
    private modalService: BsModalService,
    private commonService: CommonService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      document
        .getElementsByClassName('modal-dialog')[0]
        .classList.add('modal-dialog-centered');
    }, 100);
  }

  confirmExit(): void {
    this.result.next(true);
    this.modalService.hide();
  }

  stayOrSaveAsDraft(): void {
    this.result.next(false);
    this.modalService.hide();
  }

  close(): void {
    this.modalService.hide();
  }

  isDisabled(): boolean {
    if (this.form) {
      if (this.checkFormValidation) {
        return this.commonService.templateFormValidity || this.form.invalid;
      }
      return this.form.invalid;
    }
    return false;
  }
}
