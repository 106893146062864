import { IApiResponse } from 'src/app/shared/interfaces/common-api.interface';

export interface ISegmentsListResponse extends IApiResponse {
  data?: ISegmentData[];
}

export interface ISegmentData {
  segmentCode: string;
  segmentName: string;
  channel: string;
  modeOfExecution: string;
  segmentType: string;
}

export interface ISchedulerPayload {
  campaignCode: string;
  segmentCode: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
}

export interface ISchedulerCreateResponse extends IApiResponse {
  data?: ISchedulerResponse;
}

export interface ISchedulerResponse {
  schedulerId: string;
  message: string;
}

export interface IChannelConfig {
  type: string;
  executionDate: string;
  startTime: string;
  endTime: string;
  wabaNumber?: string;
}

export interface IWabaListResponse extends IApiResponse {
  data?: IWabaData[];
}

export interface IWabaData {
  wabaCode: string;
  wabaNumber: string;
}

export enum ChannelType {
  EMAIL = 'Email',
  SMS = 'SMS',
  WHATSAPP = 'WhatsApp',
  ORCHESTRATE = 'Orchestrate',
}

export enum ChannelLevelType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export enum SegmentType {
  MANUAL = 'Manual',
  AUTOMATED = 'Automated',
}
