import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Icons } from 'src/app/shared/utilities/icons';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit, AfterViewInit {
  icons = Icons;
  filterType: string;
  form!: FormGroup;
  selectedDate = '';
  minDate = Date;
  filters: Subject<string> = new Subject<string>();
  datepickerConfig: Partial<BsDatepickerConfig> = {
    dateInputFormat: 'DD/MM/YYYY',
    containerClass: 'theme-default',
    isAnimated: true,
    showWeekNumbers: false,
  };

  constructor(private fb: FormBuilder, private modalService: BsModalService) {}

  ngOnInit(): void {
    this.createDateFilterForm();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      document
        .getElementsByClassName('modal-dialog')[0]
        .classList.add('modal-dialog-centered');
    }, 100);
  }

  createDateFilterForm() {
    this.form = this.fb.group({
      date: [
        this.selectedDate ? new Date(this.selectedDate).getDate() : '',
        Validators.required,
      ],
    });
  }

  confirmExit(): void {
    this.modalService.hide();
  }

  applyFilters() {
    this.filters.next(this.form.controls['date'].value);
    this.modalService.hide();
  }
}
