<div class="modal-header mt-2">
  <i
    class="{{ config.modalHeader.icon.iconClass }}"
    *ngIf="config.modalHeader.icon"
  ></i>

  <h4 class="modal-title pull-left">
    &nbsp;{{ config.modalHeader.title || "" }}
  </h4>

  <button
    type="button"
    class="btn-close close pull-right"
    aria-label="Close"
    (click)="close()"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="config?.modalBody">
  {{ config.modalBody }}
</div>

<ng-container *ngIf="config.modalContent">
  <section class="modal-content">
    <div *ngIf="config.modalContent.title">
      {{ config.modalContent.title }}
    </div>

    <div *ngIf="config.modalContent.text" class="mt-4">
      {{ config.modalContent.text }}
    </div>

    <div *ngIf="config.modalContent.contentType" class="my-2">
      <span *ngIf="config.modalContent.contentType === 'textarea'">
        <textarea
          [(ngModel)]="decommissionReason"
          rows="3"
          cols="3"
          class="form-control"
          pInputTextarea
        ></textarea>
      </span>
      <div
        class="form-url copy-link"
        *ngIf="config.modalContent.contentType === 'copyURL'"
      >
        <a href="{{ formURL }}" target="_blank">{{ formURL }}</a>
      </div>
    </div>
  </section>
</ng-container>

<div class="modal-footer" *ngIf="config.modalFooter">
  <button
    type="button"
    class="btn btn-footer"
    (click)="onButtonClick(modalButtonType.CANCEL)"
  >
    {{ config.modalFooter.cancelButtonText }}
  </button>
  <button
    type="button"
    class="btn btn-footer"
    *ngIf="!config?.modalFooter?.hideConfirmButton"
    [ngClass]="{
      disabled: isDisabled()
    }"
    [disabled]="isDisabled()"
    (click)="onButtonClick(modalButtonType.CONFIRM)"
  >
    {{ config.modalFooter.confirmButtonText }}
  </button>
</div>
