import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StorageService } from '../cache/storage.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { Constants } from 'src/app/shared/utilities/constants';
import { IUserDetailsResponse } from 'src/app/shared/interfaces/user-details.interface';
import { environment } from 'src/environments/environment';
import { API_URL } from '../constants/api';
import { loginClient } from 'common-partner-login-sdk/lib/esm';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements CanActivate {
  isAppRefresh = true;
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private commonService: CommonService
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    if (
      !this.isAppRefresh &&
      localStorage.getItem(Constants.STORAGE_KEYS.ID_TOKEN)
    ) {
      return of(true);
    }
    return from(loginClient.getToken()).pipe(
      switchMap((response) => {
        const token = response.data.session.idToken.jwtToken;
        this.storageService.setItem(Constants.STORAGE_KEYS.ID_TOKEN, token);
        if (token) {
          return this.getUserDetails(token).pipe(
            map((userDetailsResponse: IUserDetailsResponse) => {
              if (!userDetailsResponse.error && userDetailsResponse.data) {
                this.isAppRefresh = false;
                this.storageService.setItem(
                  'userData',
                  userDetailsResponse.data
                );
                return true;
              } else {
                this.commonService.showErrorMessage(
                  userDetailsResponse?.errors?.[0]?.errorMessage || 'Error'
                );
                return false;
              }
            }),
            catchError((error: HttpErrorResponse) => {
              this.commonService.showError(error);
              return of(false);
            })
          );
        } else {
          return of(false);
        }
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  getUserDetails(token: string): Observable<IUserDetailsResponse> {
    return this.http.post<IUserDetailsResponse>(
      `${environment.COMPOSITE_BASE_URL}${API_URL.TOKEN}`,
      { token }
    );
  }
}
