import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL } from 'src/app/core/constants/api';
import { environment } from 'src/environments/environment';
import {
  MasterConfig,
  SaveWabaRequestPayload,
  SaveWabaResponse,
  WabaMasterConfig,
} from '../interface/create-waba.interface';
import { Observable, lastValueFrom } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  wabaMasterConfig: Promise<WabaMasterConfig>;
  wabaMasterConfigMap: any = {};

  constructor(
    private readonly http: HttpClient,
    private readonly commonService: CommonService
  ) {}

  getMasterData(): Observable<MasterConfig> {
    return this.http.get<MasterConfig>(
      `${environment.CAMPAIGN_BASE_URL}${API_URL.WABA_MASTER_CONFIG}`
    );
  }

  saveWabaDetails(
    payload: SaveWabaRequestPayload
  ): Observable<SaveWabaResponse> {
    return this.http.post<SaveWabaResponse>(
      `${environment.CAMPAIGN_BASE_URL}${API_URL.SAVE_WABA}`,
      payload
    );
  }

  getMasterDataFromAPI() {
    return lastValueFrom(
      this.http.get(
        `${environment.CAMPAIGN_BASE_URL}${API_URL.WABA_MASTER_CONFIG}`
      )
    );
  }

  async callMasterData() {
    if (
      !this.wabaMasterConfig ||
      Object.keys(this.wabaMasterConfig).length <= 0
    ) {
      const response: any = await this.getMasterDataFromAPI().catch(
        (error: HttpErrorResponse) => {
          this.commonService.showError(error);
        }
      );
      if (response && !response.error) {
        this.prepareMasterDataMap(response.data);
        this.wabaMasterConfig = response.data;
      }
    }
    return this.wabaMasterConfig;
  }

  prepareMasterDataMap(config: WabaMasterConfig) {
    const bsp = config.bsp;
    const wabaType = config.wabaType;
    let bspMap: { [key: string]: string } = {};
    let wabaTypeMap: { [key: string]: string | { [key: string]: string } } = {};
    for (let bspObj of bsp) {
      bspMap[bspObj.code] = bspObj.value;
    }
    let wabaSubTypeMap: { [key: string]: string } = {};
    for (let wabaTypeObj of wabaType) {
      if (wabaTypeObj.wabaSubType?.length) {
        for (let wabaSubType of wabaTypeObj.wabaSubType) {
          wabaSubTypeMap[wabaSubType.code] = wabaSubType.value;
        }
      }
      wabaTypeMap[wabaTypeObj.code] = wabaTypeObj.value;
    }
    wabaTypeMap['wabaSubTypeMap'] = wabaSubTypeMap;
    this.wabaMasterConfigMap = {
      bspMap: bspMap,
      wabaTypeMap: wabaTypeMap,
    };
  }

  getWabaMasterMap() {
    return this.wabaMasterConfigMap;
  }

  updateWabaStatus(payload: {
    wabaCode: string;
    active: boolean;
    statusReason: string;
  }) {
    return this.http.patch(
      `${environment.CAMPAIGN_BASE_URL}${API_URL.UPDATE_WABA_STATUS}`,
      payload
    );
  }

  testWabaNumber(wabaCode: string, recipientId: string) {
    return this.http.post(
      `${environment.CAMPAIGN_BASE_URL}${API_URL.TEST_WABA}?wabaCode=${wabaCode}`,
      {recipientId: recipientId}
    );
  }
}
