export enum GRID_DATA_TYPE {
  DATE = 'date',
  LINK = 'link',
  TEXT = 'text',
  BUTTONS = 'buttons',
}

export const METHOD_TYPE = {
  GET: 'GET',
  POST: 'POST',
  POST1: 'POST1'
};

export enum ButtonType {
  LINKBTN = 'linkButton',
  ICONBTN = 'iconButton',
  TEXTBTN = 'textButton',
  TEXTICONBTN = 'textIconButton',
  ICONTEXTLINK = 'iconTextLink',
  TEXTICONLINK = 'textIconLink',
}

export class GridConstants {
  public static readonly PAGE_SIZE = 10;
  public static readonly PAGE_SIZE_OPTIONS = [10, 20, 50];
  public static readonly SCROLL_HEIGHT = '300px';
}

export const mockCommonGridData = {
  error: false,
  data: {
    page: {
      pageNumber: 1,
      totalPageCount: 1,
      pageSize: 10,
      totalRecords: 6,
    },
    filteredResponse: [
      {
        id: 2,
        type: 'FTP',
        subType: null,
        wabaCode: 'qwertyuio',
        wabaNumber: '1234567890',
        description: '',
        organization: 'MSIL',
        division: 'DCSDC',
        vertical: 'DE',
        department: 'AGI',
        active: true,
        bsp: 'TCL',
        wabaFolderPath: 's3://sftpnonprod.campaign/dev/whatsapp/qwertyuio/',
        reactivationReason: 'Deactivated',
        defaultStatus: true,
        creationDate: '2024-05-07T08:34:56.670738Z',
        createdBy: 'Prince Tiwari - 581542',
        modifiedDate: '2024-05-07T08:34:56.670738Z',
        modifiedBy: 'Prince Tiwari - 581542',
        url: null,
      },
    ],
  },
};

export const mockCommonGridPost1Data = {
  "totalPages": 59,
  "totalElements": 234,
  "pageSize": 4,
  "pageNumber": 0,
  "numberOfElements": 4,
  "first": true,
  "last": false,
  "content": [
      {
          "content": "Hello All, Following are new buttons. please go check them out",
          "campaignName": "Campaign_dev_test_4",
          "campaignId": "Camp_989",
          "templateId": "32",
          "communicationChannel": "WHATSAPP"
      },
      {
          "content": "Hello All, Following are new buttons. please go check them out",
          "campaignName": "test queue01",
          "campaignId": "Camp_780",
          "templateId": "32",
          "communicationChannel": "WHATSAPP"
      },
      {
          "content": "Whatsapp Message",
          "campaignName": "Camp 010",
          "campaignId": "Camp_584",
          "templateId": "39",
          "communicationChannel": "WHATSAPP"
      },
      {
          "content": "Whatsapp Message",
          "campaignName": "Campaign_1_1",
          "campaignId": "Camp_582",
          "templateId": "39",
          "communicationChannel": "WHATSAPP"
      }
  ]
};
