<div class="modal-header">
  <i
    class="{{ config.modalHeader.icon.iconClass }}"
    *ngIf="config.modalHeader.icon"
  ></i>
  <h4 class="modal-title pull-left">{{ config.modalHeader.title }}</h4>
  <button
    type="button"
    class="btn-close close pull-right"
    aria-label="Close"
    (click)="close()"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="testSchedulerForm" style="width: 100%">
    <div class="col-md-12" *ngIf="selectedChannelType === channelType.EMAIL">
      <label for="email" class="form-label">{{ config.modalBody }} *</label>
      <input
        type="email"
        email
        class="form-control"
        [ngClass]="{
          'is-invalid':
            form['email'].errors &&
            (form['email'].touched || form['email'].dirty)
        }"
        id="email"
        formControlName="email"
        placeholder="Enter Email Id"
      />
      <div *ngIf="form['email'].errors" class="invalid-feedback">
        <div *ngIf="form['email'].errors['required']">Email Id is required</div>
        <div *ngIf="form['email'].errors['minlength']">
          Email Id must be at least 6 characters
        </div>
        <div *ngIf="form['email'].errors['maxlength']">
          Email Id must not exceed 50 characters
        </div>
      </div>
    </div>
    <div class="col-md-12" *ngIf="selectedChannelType !== channelType.EMAIL">
      <label for="phoneNumber" class="form-label"
        >{{ config.modalBody }} *</label
      >
      <div class="col-sm-4 input-group">
        <span class="input-group-text" id="basic-addon1">+91</span>
        <input
          type="text"
          class="form-control"
          maxlength="10"
          [ngClass]="{
            'is-invalid':
              form['phoneNumber'].errors &&
              (form['phoneNumber'].touched || form['phoneNumber'].dirty)
          }"
          id="phoneNumber"
          formControlName="phoneNumber"
          placeholder="Enter Phone Number"
        />
        <div *ngIf="form['phoneNumber'].errors" class="invalid-feedback">
          <div *ngIf="form['phoneNumber'].errors['required']">
            Phone Number is required
          </div>
          <div
            *ngIf="
              !form['phoneNumber'].errors['pattern'] &&
              form['phoneNumber'].errors['minlength']
            "
          >
            Phone Number must be of 10 digits
          </div>
          <div *ngIf="form['phoneNumber'].errors['pattern']">
            Phone Number must include numbers only
          </div>
        </div>
      </div>
    </div>
    <h6 class="mt-3" *ngIf="placeholders.length">Placeholders</h6>
    <ng-container *ngIf="placeholders.length">
      <div
        class="col-md-12"
        [ngClass]="{ 'mt-3': !first }"
        *ngFor="
          let placeholder of placeholders;
          let i = index;
          let first = first
        "
      >
        <label for="{{ placeholders[i] }}" class="form-label"
          >{{ placeholders[i] }} *</label
        >
        <input
          type="text"
          class="form-control"
          id="{{ placeholders[i] }}"
          formControlName="{{ placeholders[i] }}"
          placeholder="Enter {{ placeholders[i] }}"
          [ngClass]="{
            'is-invalid':
              form[placeholders[i]].errors &&
              (form[placeholders[i]].touched || form[placeholders[i]].dirty)
          }"
        />
        <div *ngIf="form[placeholders[i]].errors" class="invalid-feedback">
          <div *ngIf="form?.[placeholders[i]]?.errors?.['required']">
            {{ placeholders[i] }} is required
          </div>
        </div>
      </div>
    </ng-container>
  </form>
</div>
<div class="modal-footer" *ngIf="config.modalFooter">
  <button type="button" class="btn btn-footer" (click)="close()">
    {{ config.modalFooter.cancelButtonText }}
  </button>
  <button
    type="button"
    class="btn btn-footer"
    [ngClass]="{
      disabled: testSchedulerForm.invalid
    }"
    [disabled]="testSchedulerForm.invalid"
    (click)="sendRealTimeInfo(testSchedulerForm)"
  >
    {{ config.modalFooter.confirmButtonText }}
  </button>
</div>
