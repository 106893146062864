import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { FilterService } from 'primeng/api';

@Pipe({
  name: 'filterTable',
})
@Injectable({
  providedIn: 'root',
})
export class FilterTablePipe implements PipeTransform {
  static localMatch = true;
  constructor(private readonly filterService: FilterService) {}

  transform(value: any, ...args: unknown[]) {
    if (!value || !args) {
      return value;
    }
    return FilterTablePipe.filter(
      value,
      FilterTablePipe.checkFilter(args[0]),
      this.filterService
    );
  }

  static filter(items: any, filterArr: any, filterService: any) {
    const filteredItems: any = [];
    function executeLocalFilter(field: any, rowData: any, filterMeta: any) {
      let filterValue = filterMeta.value;
      const filterMatchMode = filterMeta.matchMode || 'startsWith';
      let dataFieldValue = FilterTablePipe.removeNullFromObject(rowData, field);
      if (
        dataFieldValue &&
        typeof dataFieldValue === 'string' &&
        ['dateIs', 'dateIsNot', 'dateBefore', 'dateAfter'].includes(
          filterMatchMode
        )
      ) {
        const [datePart, timePart] = dataFieldValue.split(' ');
        const [day, month, year] = datePart.split('/');
        const [hour, minute, second] = timePart.split(':');
        dataFieldValue = new Date(
          Number(year),
          Number(month) - 1,
          Number(day),
          (Number(hour) % 12) + (timePart.endsWith('PM') ? 12 : 0),
          Number(minute),
          Number(second)
        );
      }
      if (dataFieldValue) {
        const filterConstraint = filterService.filters[filterMatchMode];
        return filterConstraint(dataFieldValue, filterValue);
      } else {
        return false;
      }
    }

    Object.keys(filterArr).forEach((prop, index: number) => {
      if (filterArr.hasOwnProperty(prop)) {
        let localMatch = true;
        items.forEach((item: any) => {
          const filterField = prop;
          const filterMeta = filterArr[filterField];
          if (Array.isArray(filterMeta)) {
            for (const meta of filterMeta) {
              localMatch = executeLocalFilter(filterField, item, meta);
            }
          } else {
            localMatch = executeLocalFilter(filterField, item, filterMeta);
          }
          if (localMatch) {
            filteredItems.push(item);
          }
        });

        //this is to prevent copying items on last index.
        if (index < Object.keys(filterArr).length - 1) {
          items = JSON.parse(JSON.stringify(filteredItems));
          filteredItems.length = 0;
        }
      }
    });
    return filteredItems;
  }

  static checkFilter(filters: any) {
    const filterObjs: any = {};
    if (Object.keys(filters).length === 0) {
      return {};
    } else {
      for (const prop in filters) {
        if (filters[prop]) {
          filters[prop].forEach((filterObj: any) => {
            if (filterObj?.value?.length > 0) {
              filterObjs[prop] = filters[prop];
            }
          });
        }
      }
      return filterObjs;
    }
  }

  static removeNullFromObject(obj: any, property: any) {
    if (!obj) return '';
    return (
      (typeof obj[property] === 'string'
        ? obj[property]?.trim()
        : obj[property]) || ''
    );
  }
}
