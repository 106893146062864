<div class="placeholder-mapping" *ngIf="currentPlaceholderData">
  <div class="header">
    <h5 class="text-primary">
      {{ currentPlaceholderData.header }}
    </h5>
    <span
      class="close-icon"
      (click)="onCancelClick()"
      (keydown)="$event.preventDefault()"
    >
      &times;
    </span>
  </div>
  <div class="row body">
    <div class="col-md-12">
      <h6 *ngIf="currentPlaceholderData?.subHeading">
        {{ currentPlaceholderData.subHeading }}
      </h6>
      <h6
        *ngIf="
          currentPlaceholderData.refrenceIndex != undefined &&
          currentPlaceholderData?.key == 'form'
        "
      >
        *The form used in WhatsApp template is same as SMS template.*
      </h6>
      <div
        class="mb-3"
        [ngClass]="
          currentPlaceholderData.subHeading != 'Recipient ID'
            ? 'container'
            : 'border-class'
        "
      >
        <ng-container
          *ngFor="
            let placeholder of currentPlaceholderData.placeholderList;
            let i = index
          "
        >
          <div class="col-md-12" class="mapper-container">
            <div class="block">
              <label
                *ngIf="currentPlaceholderData.subHeading != 'Recipient ID'"
                for="{{ placeholder.label }}"
                class="form-label"
              >
                {{ placeholder.label }}
              </label>
              <p-dropdown
                [filter]="true"
                filterBy="headerName"
                [ngClass]="{
                  invalid:
                    !placeholder.value &&
                    (dropdownId.touched ||
                      dropdownId.dirty ||
                      isNextSubClicked),
                  disabled:
                    currentPlaceholderData.refrenceIndex != null ||
                    placeholder.isDisabled
                }"
                [options]="currentPlaceholderData.options"
                name="{{ placeholder.key }}{{ placeholder.label }}{{ i }}"
                placeholder="Select Placeholder"
                optionLabel="headerName"
                optionValue="headerValue"
                appendTo="body"
                (onChange)="onValueSelect(placeholder.key)"
                [(ngModel)]="placeholder.value"
                [disabled]="
                  currentPlaceholderData.refrenceIndex != null ||
                  placeholder.isDisabled == true
                "
                #dropdownId="ngModel"
              >
              </p-dropdown>
              <div
                *ngIf="
                  !placeholder.value &&
                  (dropdownId.touched || dropdownId.dirty || isNextSubClicked)
                "
              >
                <small class="validation-error">Please select a value</small>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-end footer">
    <p-button
      label="Back"
      *ngIf="currentIndex > 0"
      styleClass="p-button-outlined p-button-rounded"
      (click)="onPrevNextBtnClick('BACK')"
      (keydown)="$event.preventDefault()"
    >
    </p-button>
    <p-button
      label="Next"
      *ngIf="currentIndex < placeholdersArray.length - 1"
      styleClass="p-button-filled p-button-rounded"
      (click)="onPrevNextBtnClick('NEXT')"
      (keydown)="$event.preventDefault()"
    >
    </p-button>
    <p-button
      label="Submit"
      *ngIf="currentIndex === placeholdersArray.length - 1"
      styleClass="p-button-filled p-button-rounded"
      (click)="onPrevNextBtnClick('SUBMIT')"
      (keydown)="$event.preventDefault()"
    >
    </p-button>
  </div>
</div>
