const baseUrl =
  'https://hxkvp4iltc.execute-api.ap-south-1.amazonaws.com/dev/maruti';

export const environment = {
  production: false,
  BASE_URL: `${baseUrl}`,
  COMM_BASE_URL: `${baseUrl}/communication-service/v1/`,
  FORM_BASE_URL: `${baseUrl}/form/v1/`,
  CAMPAIGN_BASE_URL: `${baseUrl}/campaign/v1/`,
  ESB_BASE_URL: `${baseUrl}/esb-service/`,
  COMPOSITE_BASE_URL: `${baseUrl}/composite/v1/`,
  REDIRECT_URL: 'https://dev.campaign.communicationmanager.in/dashboard',
  LOGOUT_URL: 'https://dev.campaign.communicationmanager.in/login',
  AD_API_KEY: '5jtq43gpt37ioppr6jc55e1mna',
  APIKEY: 'LS8LArIgBt34vKrCFOIYp4skbhj7yUAA35UaO2tn',
  SAMPLE_FILE_DOWNLOAD:
    'https://campaign-mgr-nonprod.s3.ap-south-1.amazonaws.com/communication-service-dev/Campaign_MasterData_Test/Manual/Sample_Files/Language_Campaign-Name_Partition-Number_Date.csv',
  FORM_PREVIEW_URL: 'https://dev.formsmarutisuzuki.com',
  QLIK_SENSE_REPORT_URL: 'https://dev-reports.communicationmanager.in',
  FORM_DEFAULT_LOGO_URL:
    'https://cmgr-form-media-dev.s3.ap-south-1.amazonaws.com/logos/LogoMSIL-20240701-0636241-main.png',
};
