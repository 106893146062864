<div class="item-list-component">
  <div class="row">
    <div class="col-md-12">
      <p class="mb-2">Choose a data job from below list:</p>

      <p-listbox
        [options]="itemList"
        [(ngModel)]="selectedItem"
        optionLabel="name"
      >
        <ng-template let-item pTemplate="item">
          <div class="d-flex align-items-center gap-1 item">
            <i class="bi bi-file-earmark-text icon-size"></i>
            <span class="ps-2">{{ item.name }}</span>
          </div>
        </ng-template>
      </p-listbox>
    </div>
  </div>
  <div class="d-flex justify-content-end mt-3">
    <p-button
      label="Cancel"
      styleClass="p-button-outlined p-button-rounded me-3"
      (click)="onCancel()"
      (keydown)="$event.preventDefault()"
    >
    </p-button>
    <p-button
      label="Import"
      styleClass="p-button-filled p-button-rounded"
      [ngClass]="{ disabled: !selectedItem }"
      [disabled]="!selectedItem"
      (click)="onClickImport()"
      (keydown)="$event.preventDefault()"
    >
    </p-button>
  </div>
</div>
