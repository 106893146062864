import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { PermissionService } from '../../services/permission.service';
import { CommonAuthConstants } from '../../authorization/auth.constants';

@Directive({
  selector: '[appElementPermissible]',
})
export class IsElementPermissibleDirective implements OnInit {
  @Input()
  appElementPermissible: string;
  constructor(
    private readonly permissionService: PermissionService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<HTMLElement>
  ) {}
  ngOnInit() {
    this.checkPermission();
  }

  public getUserPermissions(): Array<string> {
    return this.permissionService.getPermission();
  }

  private checkPermission(): void {
    const _permissions: Array<string> = this.getUserPermissions();
    this.viewContainerRef.createEmbeddedView(this.templateRef);
    if (
      _permissions.indexOf(
        `${CommonAuthConstants.common}:${this.appElementPermissible}`
      ) === -1
    ) {
      this.viewContainerRef.clear();
    }
  }
}
