<div class="recurrence-pattern-container">
  <div class="d-flex flex-wrap gap-5 mt-1 ms-2">
    <p-tabMenu
      [model]="tabList"
      [activeItem]="activeTab"
      (activeItemChange)="onActiveItemChange($event)"
    ></p-tabMenu>
  </div>
  <div class="samay-config mt-4" *ngIf="activeTab">
    <ng-container [ngSwitch]="activeTab.id">
      <!-- for daily config -->
      <div class="ms-2" *ngSwitchCase="tabMap.DAILY">
        <div class="d-flex justify-content-start align-items-center gap-3">
          <p-radioButton
            [inputId]="dailyOptionMap.EVERY_N_DAY"
            [value]="dailyOptionMap.EVERY_N_DAY"
            [(ngModel)]="selectedDailyTab"
            name="{{ activeTab.id }}{{ dailyOptionMap.EVERY_N_DAY }}"
          ></p-radioButton>
          <label
            [for]="dailyOptionMap.EVERY_N_DAY"
            class="d-flex me-2 radio-btn cursor-pointer align-items-center gap-3"
          >
            Every
            <p-dropdown
              [options]="daysList"
              appendTo="body"
              [(ngModel)]="selectedDayInterval"
            >
            </p-dropdown>
            day(s)</label
          >
        </div>
        <div class="d-flex justify-content-start align-items-center gap-3 mt-4">
          <p-radioButton
            [inputId]="dailyOptionMap.EVERYDAY"
            [value]="dailyOptionMap.EVERYDAY"
            [(ngModel)]="selectedDailyTab"
            name="{{ activeTab.id }}{{ dailyOptionMap.EVERYDAY }}"
          ></p-radioButton>
          <label
            [for]="dailyOptionMap.EVERYDAY"
            class="d-flex me-2 radio-btn cursor-pointer align-items-center gap-3"
          >
            Everyday</label
          >
        </div>
      </div>

      <!-- for weekly config -->
      <div class="ms-2" *ngSwitchCase="tabMap.WEEKLY">
        <label class="d-flex radio-btn align-items-center gap-3">
          Recur every week on:</label
        >
        <div class="d-flex justify-content-start align-items-center gap-4 mt-4">
          <div class="d-flex gap-2" *ngFor="let dayName of daysNameList">
            <input
              class="form-check-input"
              type="checkbox"
              [id]="dayName.key"
              [value]="dayName"
              [(ngModel)]="dayName.isSelected"
            />
            <label class="form-check-label cursor-pointer" [for]="dayName.key">
              {{ dayName.label }}
            </label>
          </div>
        </div>
      </div>

      <!-- for monthly config -->
      <div class="ms-2" *ngSwitchCase="tabMap.MONTHLY">
        <div class="d-flex justify-content-start align-items-center gap-3">
          <p-radioButton
            [inputId]="monthlyOptionMap.DAY"
            [value]="monthlyOptionMap.DAY"
            [(ngModel)]="selectedMonthlyTab"
            name="{{ activeTab.id }}{{ monthlyOptionMap.DAY }}"
          ></p-radioButton>
          <label
            [for]="monthlyOptionMap.DAY"
            class="d-flex me-2 radio-btn cursor-pointer align-items-center gap-3"
          >
            Day
            <p-dropdown
              [options]="monthlyDaysList"
              appendTo="body"
              [(ngModel)]="monthlyDayCriteria.day"
            >
            </p-dropdown>
            of every month
          </label>
        </div>
        <div class="d-flex justify-content-start align-items-center gap-3 mt-4">
          <p-radioButton
            [inputId]="monthlyOptionMap.WEEKDAY"
            [value]="monthlyOptionMap.WEEKDAY"
            [(ngModel)]="selectedMonthlyTab"
            name="{{ activeTab.id }}{{ monthlyOptionMap.WEEKDAY }}"
          ></p-radioButton>
          <label
            [for]="monthlyOptionMap.WEEKDAY"
            class="d-flex me-2 radio-btn cursor-pointer align-items-center gap-3"
          >
            The
            <p-dropdown
              [options]="weekDayList"
              optionLabel="label"
              optionValue="key"
              appendTo="body"
              [(ngModel)]="monthlyWeekDayCriteria.week"
            >
            </p-dropdown>
            <p-dropdown
              [options]="weekDayNameList"
              optionLabel="label"
              optionValue="key"
              appendTo="body"
              [(ngModel)]="monthlyWeekDayCriteria.day"
            >
            </p-dropdown>
            of every month
          </label>
        </div>
      </div>

      <!-- for yearly config -->
      <div class="ms-2" *ngSwitchCase="tabMap.YEARLY">
        <label class="d-flex radio-btn align-items-center gap-3">
          Recur every year</label
        >
        <div class="d-flex justify-content-start align-items-center gap-3 mt-4">
          <p-radioButton
            [inputId]="yearlyOptionMap.DAY"
            [value]="yearlyOptionMap.DAY"
            [(ngModel)]="selectedYearlyTab"
            name="{{ activeTab.id }}{{ yearlyOptionMap.DAY }}"
          ></p-radioButton>
          <label
            [for]="yearlyOptionMap.DAY"
            class="d-flex me-2 radio-btn cursor-pointer align-items-center gap-3"
          >
            On
            <p-dropdown
              [options]="monthNameList"
              appendTo="body"
              optionLabel="label"
              optionValue="key"
              [(ngModel)]="yearlyDayCriteria.month"
            >
            </p-dropdown>
            <p-dropdown
              [options]="monthlyDaysList"
              appendTo="body"
              [(ngModel)]="yearlyDayCriteria.day"
            >
            </p-dropdown>
          </label>
        </div>
        <div class="d-flex justify-content-start align-items-center gap-3 mt-4">
          <p-radioButton
            [inputId]="yearlyOptionMap.WEEKDAY"
            [value]="yearlyOptionMap.WEEKDAY"
            [(ngModel)]="selectedYearlyTab"
            name="{{ activeTab.id }}{{ yearlyOptionMap.WEEKDAY }}"
          ></p-radioButton>
          <label
            [for]="yearlyOptionMap.WEEKDAY"
            class="d-flex me-2 radio-btn cursor-pointer align-items-center gap-3"
          >
            On the
            <p-dropdown
              [options]="weekDayList"
              optionLabel="label"
              optionValue="key"
              appendTo="body"
              [(ngModel)]="yearlyWeekDayCriteria.week"
            >
            </p-dropdown>
            <p-dropdown
              [options]="weekDayNameList"
              optionLabel="label"
              optionValue="key"
              appendTo="body"
              [(ngModel)]="yearlyWeekDayCriteria.day"
            >
            </p-dropdown>
            of
            <p-dropdown
              [options]="monthNameList"
              appendTo="body"
              optionLabel="label"
              optionValue="key"
              [(ngModel)]="yearlyWeekDayCriteria.month"
            >
            </p-dropdown>
          </label>
        </div>
      </div>
    </ng-container>
  </div>
</div>
