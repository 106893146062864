import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from 'src/app/shared/utilities/constants';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  requestList: HttpRequest<any>[] = [];

  constructor(private spinner: NgxSpinnerService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Modify the request or perform any other operations before sending it
    if (request.headers.has('hideLoader')) {
      const headers: Map<string, any> = request.headers['headers'];
      headers.delete('hideloader');
      request.headers.set('headers', JSON.stringify(headers));
    } else {
      this.requestList.push(request);
      this.spinner.show();
    }
    // Clone the request and add custom headers, authorization, etc.
    const modifiedRequest = request.clone({
      // Add headers or modify request properties
      headers: request.headers
        .set('Authorization', this.getToken())
        .set('x-api-key', environment.APIKEY),
    });

    // Pass the modified request to the next interceptor or the HTTP handler
    return next
      .handle(modifiedRequest)
      .pipe(finalize(() => this.removeRequest(request)));
  }

  removeRequest(request: HttpRequest<any>) {
    const index = this.requestList.indexOf(request);
    if (index >= 0) {
      this.requestList.splice(index, 1);
    }
    if (this.requestList.length === 0) {
      this.spinner.hide();
    }
  }

  getToken() {
    const token = localStorage.getItem(Constants.STORAGE_KEYS.ID_TOKEN);
    return token ? JSON.parse(token) : '';
  }
}
