import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxUploaderModule } from 'ngx-uploader';
import { NgSelectModule } from '@ng-select/ng-select';
import { DragDropModule } from 'primeng/dragdrop';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import {
  DialogService,
  DynamicDialogModule,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { ListboxModule } from 'primeng/listbox';
import { StepsModule } from 'primeng/steps';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';
import { EditorModule } from 'primeng/editor';
import { TabMenuModule } from 'primeng/tabmenu';

import { OrgDetailsService } from './services/org-details.service';
import { SchedulerTestService } from './services/scheduler-test.service';

import { PaginatorComponent } from './components/paginator/paginator.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { PreviewModalComponent } from './components/preview-modal/preview-modal.component';
import { SchedulerTestModalComponent } from './components/scheduler-test-modal/scheduler-test-modal.component';
import { FilterDropdownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { CommonDataGridComponent } from './components/common-data-grid/common-data-grid.component';
import { PreviewFormComponent } from './components/preview-form/preview-form.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CommonGridComponent } from './components/common-grid/common-grid.component';
import { LinkDirective } from './directives/link/link.directive';
import { SearchHeaderComponent } from './components/search-header/search-header.component';
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { SelectChannelDialogComponent } from './components/select-channel-dialog/select-channel-dialog.component';
import { PlaceholderMappingComponent } from './components/placeholder-mapping/placeholder-mapping.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputNumberModule } from 'primeng/inputnumber';
import { EditorTemplateComponent } from './components/editor-template/editor-template.component';
import { RecurrencePatternComponent } from './components/recurrence-pattern/recurrence-pattern.component';
import { DataListComponent } from './components/data-list/data-list.component';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { ReschedulePopupComponent } from './components/reschedule-popup/reschedule-popup.component';

@NgModule({
  declarations: [
    PaginatorComponent,
    FileUploaderComponent,
    ConfirmationModalComponent,
    PreviewModalComponent,
    SchedulerTestModalComponent,
    FilterDropdownComponent,
    TimePickerComponent,
    CommonDataGridComponent,
    PreviewFormComponent,
    CommonGridComponent,
    LinkDirective,
    SearchHeaderComponent,
    ConfirmPopupComponent,
    SelectChannelDialogComponent,
    PlaceholderMappingComponent,
    EditorTemplateComponent,
    RecurrencePatternComponent,
    DataListComponent,
    SafeHtmlPipe,
    ReschedulePopupComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUploaderModule,
    NgSelectModule,
    TableModule,
    ToastModule,
    ButtonModule,
    CalendarModule,
    DropdownModule,
    InputTextModule,
    FileUploadModule,
    ScrollPanelModule,
    MultiSelectModule,
    SelectButtonModule,
    InputTextareaModule,
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    TypeaheadModule.forRoot(),
    DynamicDialogModule,
    RadioButtonModule,
    TabViewModule,
    AccordionModule,
    DialogModule,
    ListboxModule,
    StepsModule,
    CheckboxModule,
    MenuModule,
    NgxSpinnerModule,
    ConfirmDialogModule,
    InputSwitchModule,
    EditorModule,
    TabMenuModule,
  ],
  exports: [
    DragDropModule,
    NgxUploaderModule,
    BsDatepickerModule,
    TooltipModule,
    ModalModule,
    NgSelectModule,
    TableModule,
    ToastModule,
    ButtonModule,
    CalendarModule,
    DropdownModule,
    InputTextModule,
    FileUploadModule,
    ScrollPanelModule,
    MultiSelectModule,
    SelectButtonModule,
    InputTextareaModule,
    PaginatorComponent,
    FileUploaderComponent,
    ConfirmationModalComponent,
    SchedulerTestModalComponent,
    DynamicDialogModule,
    RadioButtonModule,
    TabViewModule,
    CommonDataGridComponent,
    AccordionModule,
    DialogModule,
    ListboxModule,
    StepsModule,
    CheckboxModule,
    MenuModule,
    PreviewFormComponent,
    MenuModule,
    NgxSpinnerModule,
    CommonGridComponent,
    LinkDirective,
    SearchHeaderComponent,
    ConfirmPopupComponent,
    ConfirmDialogModule,
    PlaceholderMappingComponent,
    InputSwitchModule,
    InputNumberModule,
    EditorTemplateComponent,
    EditorModule,
    RecurrencePatternComponent,
    TabMenuModule,
    DataListComponent,
    SafeHtmlPipe,
    ReschedulePopupComponent,
  ],
  providers: [
    OrgDetailsService,
    MessageService,
    SchedulerTestService,
    DialogService,
    DynamicDialogRef,
    DynamicDialogConfig,
  ],
  entryComponents: [
    PreviewModalComponent,
    FilterDropdownComponent,
    TimePickerComponent,
  ],
})
export class SharedModule {}
