import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { SEGMENT_TYPE } from 'src/app/core/constants/common';
import { SchedularActionType } from 'src/app/modules/campaign-scheduler/constants/scheduler-listing.constant';
import { GridService } from '../common-grid/common-grid.service';

@Component({
  selector: 'app-reschedule-popup',
  templateUrl: './reschedule-popup.component.html',
  styleUrls: ['./reschedule-popup.component.scss'],
})
export class ReschedulePopupComponent implements OnChanges {
  @Input() popupData: any;
  @Output() closeEventEmitter: EventEmitter<boolean> = new EventEmitter(false);
  @Output() submitEventEmitter: EventEmitter<object> = new EventEmitter();

  type: string;
  typeMap = SchedularActionType;
  resumeLater = false;
  SEGMENT_TYPE = SEGMENT_TYPE;
  disableSwitch: boolean;

  constructor(private readonly commonGridService: GridService) {}

  ngOnChanges() {
    if (this.popupData) {
      this.resumeLater = false;
      this.disableSwitch = false;
      this.type = this.popupData.type;
      this.popupData = {
        rowData: this.popupData.rowData,
        type: this.popupData.type,
        ...this.popupData.data,
      };
      if (this.popupData.isResumeDisabled) {
        this.disableSwitch = true;
        this.resumeLater = true;
        this.popupData.resumeDate = '';
      }
      // if (
      //   this.popupData?.rowData?.status !== 'Paused' &&
      //   !this.popupData?.rowData?.frequency
      // ) {
      //   if (
      //     this.commonGridService.isDateAfterToday(
      //       this.popupData?.rowData?.executionStartDate,
      //       'DD-MM-YYYY'
      //     )
      //   ) {
      //     this.disableSwitch = false;
      //     this.resumeLater = false;
      //     this.popupData.resumeDate = moment().add(1, 'day').toDate();
      //   } else if (
      //     this.commonGridService.isDateAfterToday(
      //       this.popupData?.rowData?.executionStartDate,
      //       'DD-MM-YYYY HH:mm'
      //     )
      //   ) {
      //     this.disableSwitch = true;
      //     this.resumeLater = true;
      //     this.popupData.resumeDate = '';
      //   }
      // }
    }
  }

  onCancelClick() {
    this.closeEventEmitter.emit(true);
  }

  onConfirmClick() {
    this.submitEventEmitter.emit({
      type: this.type,
      data: this.popupData,
      rowData: { ...this.popupData.rowData },
    });
  }

  toggleSwitch() {
    this.popupData.resumeDate = this.resumeLater ? this.popupData.minDate : '';
  }
}
