import { Injectable } from '@angular/core';
import { fromEvent, timer, merge, Observable } from 'rxjs';
import { debounceTime, throttleTime, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserInactivityService {
  private userInactive = false;
  private userInactiveObservable: Observable<boolean>;

  constructor() {
    this.init();
  }

  private init() {
    const inactivityDuration = 29 * 60 * 1000;

    const activity$ = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'keydown')
    ).pipe(throttleTime(1000));

    // If any of the specified events occur, reset the timer
    activity$.subscribe(() => {
      this.resetTimer();
    });

    this.userInactiveObservable = activity$.pipe(
      switchMap(() => {
        return timer(inactivityDuration).pipe(debounceTime(0));
      }),
      map(() => {
        this.userInactive = true;
        return this.userInactive;
      })
    );
  }

  private resetTimer() {
    this.userInactive = false;
  }

  get userInactive$(): Observable<boolean> {
    return this.userInactiveObservable;
  }
}
