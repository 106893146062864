import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { IPaginator } from '../../interfaces/paginator.interface';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() paginationFilter!: IPaginator;
  @Output() pageChange: EventEmitter<number> = new EventEmitter();
  @Output() rowsChanged: EventEmitter<number> = new EventEmitter();

  currentPage: number;
  maxSize = 7;
  rows = 6;

  ngOnInit() {
    this.currentPage = this.paginationFilter.pageNumber;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['paginationFilter'] &&
      changes['paginationFilter']?.currentValue?.pageNumber !==
        changes['paginationFilter']?.previousValue?.pageNumber
    ) {
      this.paginationFilter = { ...changes['paginationFilter'].currentValue };
      this.currentPage = 0;
    }
  }

  changeRow() {
    this.rowsChanged.emit(this.rows);
  }

  pageChanged(page: PageChangedEvent) {
    this.pageChange.emit(page.page);
    this.currentPage = page.page;
  }
}
