import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';
import { API_URL } from 'src/app/core/constants/api';
import { environment } from 'src/environments/environment';
import { IUserDetails } from '../interfaces/user-details.interface';
import { StorageService } from 'src/app/core/cache/storage.service';
import { Constants } from 'src/app/shared/utilities/constants';
import { Observable, Subject } from 'rxjs';
import { MIME_TYPE } from 'src/app/core/constants/mime-types';
import { ICampaignsResponse } from '../interfaces/common-api.interface';
import { Message, MessageService } from 'primeng/api';
import * as moment from 'moment';
import { CommonMessages } from 'src/app/core/constants/common';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  isTemplateFormValid = false;
  selMediaAttachmentType: string;
  enableDownloadSegment$ = new Subject<boolean>();
  generateReport$ = new Subject();
  private history: string[] = [];

  constructor(
    private readonly http: HttpClient,
    private readonly storageService: StorageService,
    private readonly messageService: MessageService,
    private readonly router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  get templateFormValidity(): boolean {
    return this.isTemplateFormValid;
  }
  set templateFormValidity(val: boolean) {
    this.isTemplateFormValid = val;
  }

  get mediaAttachment(): string {
    return this.selMediaAttachmentType;
  }
  set mediaAttachment(mediaType: string) {
    this.selMediaAttachmentType = mediaType;
  }

  getFilterList(type: string, payload: Params) {
    return this.http.get<any>(
      `${environment.COMM_BASE_URL}${API_URL.DROPDOWN}/${type}`,
      { params: payload }
    );
  }

  getUserData(): IUserDetails {
    if (this.storageService?.getItem(Constants.STORAGE_KEYS.USER_DATA)) {
      return JSON.parse(
        this.storageService.getItem(Constants.STORAGE_KEYS.USER_DATA) || ''
      );
    }
    return {} as IUserDetails;
  }

  getReportStatus(startEndTime: any[], campaignId: string) {
    return this.http.get(
      `${environment.COMM_BASE_URL}${API_URL.REPORT}/${
        API_URL.GENERATE
      }/file/${campaignId}?startDate=${moment(startEndTime[0]).format(
        'DD-MM-YYYY'
      )}&endDate=${moment(startEndTime[1]).format('DD-MM-YYYY')}`.replace(
        'communication-service',
        'reporting-service'
      ),
      {
        headers: new HttpHeaders({
          hideLoader: 'true',
        }),
        observe: 'response',
      }
    );
  }

  downloadReport(campaignId: string, fileName: string) {
    return this.http.get(
      `${environment.COMM_BASE_URL}${API_URL.REPORT}/${API_URL.DOWNLOAD}/${campaignId}/${fileName}`.replace(
        'communication-service',
        'reporting-service'
      ),
      {
        headers: new HttpHeaders({
          Accept: MIME_TYPE.XLSX,
        }),
        responseType: 'blob',
        observe: 'response',
      }
    );
  }

  logout(): Observable<any> {
    return this.http.delete<any>(
      `${environment.COMPOSITE_BASE_URL}${API_URL.TOKEN}`
    );
  }

  getCampaigns(): Observable<ICampaignsResponse> {
    return this.http.get<ICampaignsResponse>(
      `${environment.CAMPAIGN_BASE_URL}${API_URL.LIST}`
    );
  }

  showError(errorResponse: HttpErrorResponse): void {
    if (errorResponse?.status === 504) {
      this.showErrorMessage(
        'Currently services are down. Please try again after some time!'
      );
    } else if (errorResponse?.status === 403) {
      this.showErrorMessage(
        errorResponse?.error?.message ||
          'User is not authorized to access this resource',
        {
          sticky: true,
        }
      );
    } else if (errorResponse?.error?.errors?.length) {
      this.showErrorMessage(
        errorResponse?.error?.errors[0].errorMessage || 'Something went wrong!'
      );
    } else {
      this.showErrorMessage(CommonMessages.ERROR.SERVICE_UNAVILABLE_ERROR);
    }
  }

  showToast(message: Message, propertyObj?: object): void {
    if (propertyObj) {
      message = Object.assign(message, propertyObj);
    }
    this.messageService.add(message);
  }

  showSuccessMessage(message: string, propertyObj?: object): void {
    const messageObj = {
      severity: 'success',
      detail: message,
    };
    this.showToast(messageObj, propertyObj);
  }

  showInfoMessage(message: string, propertyObj?: object): void {
    const messageObj = {
      severity: 'info',
      detail: message,
    };
    this.showToast(messageObj, propertyObj);
  }

  showWarningMessage(message: string, propertyObj?: object): void {
    const messageObj = {
      severity: 'warn',
      detail: message,
    };
    this.showToast(messageObj, propertyObj);
  }

  showErrorMessage(message: string, propertyObj?: object): void {
    const messageObj = {
      severity: 'error',
      detail: message,
    };
    this.showToast(messageObj, propertyObj);
  }

  encodeData(data: string) {
    return btoa(data);
  }

  decodeData(encodedData: string) {
    return atob(encodedData);
  }

  getPreviousUrl(): string {
    if (this.history.length) {
      return this.history[this.history.length - 1];
    }
    return '/templates';
  }

  clearHistory() {
    this.history = [];
  }
}
