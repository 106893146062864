<p-toast></p-toast>
<div class="confirm-dialog-unique">
  <p-confirmDialog></p-confirmDialog>
</div>
<app-header *ngIf="userData"></app-header>
<div *ngIf="userData" class="app-main-container">
  <app-sidebar></app-sidebar>
  <div class="main-content">
    <div class="container-fluid inner">
      <router-outlet>
        <ngx-spinner size="medium" type="ball-clip-rotate">
          <p class="text-white">Loading...</p></ngx-spinner
        >
      </router-outlet>
    </div>
  </div>
</div>
<router-outlet *ngIf="!userData">
  <ngx-spinner size="medium" type="ball-clip-rotate">
    <p class="text-white">Loading...</p></ngx-spinner
  >
</router-outlet>
