import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss'],
})
export class FilterDropdownComponent implements OnInit, AfterViewInit {
  list = [];
  lastPage: boolean;
  selectedData = [];
  listingPagination: { page: number; size: number };
  filterType: string;
  form!: FormGroup;
  filters: Subject<[]> = new Subject<[]>();

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.createFilterForm();
  }

  confirmExit(): void {
    this.modalService.hide();
  }

  onScroll() {
    if (!this.lastPage) {
      this.listingPagination = {
        ...this.listingPagination,
        page: this.listingPagination.page + 1,
      };
      this.commonService
        .getFilterList(this.filterType, this.listingPagination)
        .subscribe((data: { last: boolean; content: [] }) => {
          this.lastPage = data.last;
          this.list = [...this.list, ...data.content];
        });
    }
  }

  createFilterForm() {
    this.form = this.fb.group({
      appliedFilters: [this.selectedData, Validators.required],
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      document
        .getElementsByClassName('modal-dialog')[0]
        .classList.add('modal-dialog-centered');
    }, 100);
  }

  applyFilters() {
    this.filters.next(this.form.controls['appliedFilters'].value);
    this.modalService.hide();
  }
}
