import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './core/components/login/login.component';
import { AuthConstants } from './core/authorization/auth.constants';
import { RoleGuard } from './core/guards/role.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'templates',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./modules/templates/templates.module').then(
        (m) => m.TemplatesModule
      ),
    data: {
      key: AuthConstants.TEMPLATE,
    },
  },
  {
    path: 'segments',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./modules/segments/segments.module').then(
        (m) => m.SegmentsModule
      ),
    data: {
      key: AuthConstants.TARGET_SEGMENT,
    },
  },
  {
    path: 'campaigns',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./modules/campaigns/campaigns.module').then(
        (m) => m.CampaignsModule
      ),
    data: {
      key: AuthConstants.CAMPAIGN,
    },
  },
  {
    path: 'campaign-scheduler',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./modules/campaign-scheduler/campaign-scheduler.module').then(
        (m) => m.CampaignSchedulerModule
      ),
    data: {
      key: AuthConstants.CAMPAIGN_SCHEDULER,
    },
  },
  {
    path: 'reports',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./modules/reports/reports.module').then((m) => m.ReportsModule),
    data: {
      key: AuthConstants.REPORT,
    },
  },
  {
    path: 'form-design',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./modules/form-design/form-design.module').then(
        (m) => m.FormDesignModule
      ),
    data: {
      key: AuthConstants.WEB_FORM,
    },
  },
  {
    path: 'configuration',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./modules/configuration/configuration.module').then(
        (m) => m.ConfigurationModule
      ),
    data: {
      key: AuthConstants.CONFIGURATION,
    },
  },
  { path: '**', redirectTo: '/templates', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
