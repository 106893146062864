import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.scss'],
})
export class SearchHeaderComponent implements OnChanges {
  @Input() searchOptions: { code: string; value: string }[];
  @Input() headerText: string;
  @Input() isSearchSection = true;
  @Input() newBtnConfig: { btnText: string; isView?: boolean };

  @Output() onSearchEvent: EventEmitter<any> = new EventEmitter();
  @Output() onNewBtnEvent: EventEmitter<any> = new EventEmitter();

  filterCriteria: { code: string; value: string } = {} as {
    code: string;
    value: string;
  };
  searchedKeyword = '';
  searchMenu: MenuItem[] = [];

  ngOnChanges() {
    this.searchedKeyword = '';
    if (!this.searchMenu.length) this.preProcessData();
  }

  preProcessData() {
    if (this.searchOptions?.length) {
      this.searchMenu.push({ label: 'Search by', disabled: true });
      this.searchOptions.forEach((menu) => {
        this.searchMenu.push({
          label: menu.value,
          command: () => {
            this.searchedKeyword = '';
            this.filterCriteria = menu;
          },
        });
      });
      this.filterCriteria = this.searchOptions[0] as {
        code: string;
        value: string;
      };
    }
  }

  search(): void {
    this.onSearchEvent.emit({
      filterType: this.filterCriteria,
      filterValue: this.searchedKeyword,
    });
  }

  onNewBtnClick() {
    this.onNewBtnEvent.emit();
  }
}
