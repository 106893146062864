import { Component, OnDestroy, OnInit } from '@angular/core';
import { loginClient } from 'common-partner-login-sdk/lib/esm';
import { environment } from 'src/environments/environment';
import { StorageService } from './core/cache/storage.service';
import { Constants } from './shared/utilities/constants';
import { Subscription, interval, startWith, switchMap } from 'rxjs';
import { CommonService } from './shared/services/common.service';
import { MIME_TYPE } from './core/constants/mime-types';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  userData: any = null;
  subscription$: Subscription;
  timeInterval$: Subscription;

  constructor(
    private readonly storageService: StorageService,
    private readonly commonService: CommonService
  ) {}

  ngOnInit() {
    loginClient.init(environment.AD_API_KEY);
    this.storageService.loginLogout.subscribe((value: string) => {
      this.userData = value === 'login' ? this.getToken() : null;
    });
    this.observeReportGeneration();
  }

  getToken() {
    const token = localStorage.getItem(Constants.STORAGE_KEYS.ID_TOKEN);
    return token ? JSON.parse(token) : '';
  }

  observeReportGeneration(): void {
    this.subscription$ = this.commonService.generateReport$.subscribe(
      (report: any) => {
        this.commonService.showInfoMessage(
          'Report generation has started. We will notify once it is completed'
        );
        if (report.dateRange.length) {
          this.timeInterval$ = interval(2000)
            .pipe(
              startWith(0),
              switchMap(() =>
                this.commonService.getReportStatus(
                  report.dateRange,
                  report.campaignId
                )
              )
            )
            .subscribe(
              (res: any) => {
                if (res?.body.status === 'Completed') {
                  this.downloadDetailedReport(res.body.id, res.body.fileName);
                }
              },
              (err) => {
                this.commonService.showErrorMessage(
                  'Detailed report generation failed. Please try again'
                );
              }
            );
        }
      }
    );
  }

  downloadDetailedReport(id: string, name: string): void {
    this.commonService.downloadReport(id, name).subscribe((response: any) => {
      this.timeInterval$.unsubscribe();
      const blob = new Blob([response.body], {
        type: MIME_TYPE.XLSX,
      });
      this.commonService.showSuccessMessage(
        'Detailed report generated successfully'
      );
      saveAs(blob, 'Detailed Report');
    });
  }

  ngOnDestroy() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
    if (this.timeInterval$) {
      this.timeInterval$.unsubscribe();
    }
  }
}
