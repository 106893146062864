<span class="ql-formats">
  <select class="ql-font">
    <option selected></option>
    <option value="serif">Serif</option>
    <option value="monospace">Monospace</option>
    <option value="times-new-roman">Times New Roman</option>

    <option value="arial">Arial</option>
    <option value="helvetica">Helvetica</option>
    <option value="georgia">Georgia</option>
    <option value="verdana">Verdana</option>
    <option value="calibri">Calibri</option>
    <option value="garamond">Garamond</option>
    <option value="tahoma">Tahoma</option>
  </select>
  <select class="ql-size"></select>
</span>
<span class="ql-formats">
  <button class="ql-bold"></button>
  <button class="ql-italic"></button>
  <button class="ql-underline"></button>
  <button class="ql-strike"></button>
</span>
<span class="ql-formats">
  <select class="ql-color"></select>
  <select class="ql-background"></select>
  <button class="ql-list" value="ordered"></button>
  <button class="ql-list" value="bullet"></button>
</span>
<span class="ql-formats">
  <button class="ql-indent" value="-1"></button>
  <button class="ql-indent" value="+1"></button>
</span>
<span class="ql-formats">
  <select class="ql-align"></select>
  <button class="ql-link"></button>
</span>
