<section class="list-main-container mb-3">
  <div class="header-table">
    <h1>{{ headerText }}</h1>
    <div class="search-info gap-4" *ngIf="isSearchSection">
      <span class="p-input-icon-left">
        <p-menu
          #menu
          [model]="searchMenu"
          [popup]="true"
          appendTo="body"
        ></p-menu>
        <i
          class="pi pi-angle-down cursor-pointer"
          (click)="menu.toggle($event)"
          (keydown)="$event.preventDefault()"
        ></i>
        <input
          type="text"
          pInputText
          [(ngModel)]="searchedKeyword"
          placeholder="Search by {{ filterCriteria.value }}"
          (keydown.enter)="search()"
        />
        <span class="p-input-icon-right">
          <i
            class="bi bi-search cursor-pointer"
            (click)="search()"
            (keydown)="$event.preventDefault()"
          ></i>
        </span>
      </span>
      <p-button
        *ngIf="newBtnConfig && newBtnConfig.isView != false"
        [label]="newBtnConfig.btnText"
        styleClass="btn-common"
        (click)="onNewBtnClick()"
        (keydown)="$event.preventDefault()"
      >
        <div class="icon">
          <i class="bi bi-plus font-lg"></i>
        </div>
      </p-button>
    </div>
  </div>
</section>
