import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Icons } from '../../utilities/icons';

@Component({
  selector: 'app-select-channel-dialog',
  templateUrl: './select-channel-dialog.component.html',
  styleUrls: ['./select-channel-dialog.component.scss'],
})
export class SelectChannelDialogComponent implements OnInit {
  icons = Icons;
  channels: Array<any> = [];

  constructor(
    private readonly dynamicDialogref: DynamicDialogRef,
    public readonly config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.channels = this.config.data.channel;
  }

  channelSelected(channel: string) {
    this.dynamicDialogref.close(channel);
  }
}
