import { IApiResponse } from 'src/app/shared/interfaces/common-api.interface';

export interface ICampaignListResponse extends IApiResponse {
  data?: ICampaignListData;
}

export interface ICampaignListData {
  page: ICampaignPage;
  filteredResponse: ICampaignFilteredData[];
}

export interface ICampaignPage {
  pageNumber: number;
  totalRecords: number;
  pageSize: number;
  totalPageCount: number;
}

export interface ICampaignFilteredData {
  code: string;
  name: string;
  type: string;
  status: string;
  description: string;
  startDate: string;
  endDate: string;
  organization: string;
  vertical: string;
  division: string;
  department: string;
  ringiNumber: string;
  templateDetails: ITemplateDetail[];
  createdBy: string;
  createdDate: string;
  modifiedBy: string;
  modifiedDate: string;
}

export interface ITemplateDetail {
  type: string;
  code: string;
}

export interface CampaignListRequest {
  filterValue: string;
  pageNumber: number;
  pageSize: number;
  filterType: string;
  status: string[];
}

export enum CampaignStatus {
  DRAFT = 'Draft',
  PUBLISHED = 'Published',
}
