import { IApiResponse } from 'src/app/shared/interfaces/common-api.interface';

export interface ISchedulerListResponse extends IApiResponse {
  data?: ISchedulerListData;
}

export interface ISchedulerListData {
  page: ISchedulerPage;
  filteredResponse: ISchedulerFilteredData[];
}

export interface ISchedulerPage {
  pageNumber: number;
  totalRecords: number;
  pageSize: number;
  totalPageCount: number;
}

export interface ISchedulerFilteredData {
  campaignCode: string;
  campaignName: string;
  segmentName: string;
  templateCode: string;
  campaignStartDate: string;
  campaignEndDate: string;
  executionStartDate: string;
  executionEndDate: string;
  status: string;
  schedulerCode: string;
  organization: string;
  division: string;
  vertical: string;
  department: string;
  createdBy: string;
  frequency?: string;
  segmentType: string;
  lastExecutedOn?: string;
  schedulerDetail?: any;
  channel: string;
  modeOfExecution: string;
  segmentCode: string;
  wabaCode: string;
  primaryChannel: string;
  secondaryChannel: string;
  linkWaitTime: string;
  channelWaitTime: string;
  urlClickTrack: boolean;
  orchestrationType?: string;
}

export interface SchedulerListRequest {
  filterType: string;
  filterValue: string;
  pageNumber: number;
  pageSize: number;
}

export enum SchedulerStatus {
  SCHEDULED = 'Scheduled',
  IN_PROGRESS = 'In Progress',
  EXECUTED = 'Executed',
  RESCHEDULED = 'Rescheduled',
  RESCHEDULING_FAILED = 'Rescheduling Failed',
  SCHEDULING_FAILED = 'Scheduling Failed',
  UNSCHEDULING_FAILED = 'Unscheduling Failed',
  FAILED = 'Failed',
  TO_BE_SCHEDULED = 'To Be Scheduled',
  PAUSED = 'Paused',
  CANCELLED = 'Cancelled',
  INVALID_SEGMENT = 'Invalid Segment',
}

export interface ItemConstants {
  label: string;
  key: string;
}

export interface ItemValue {
  primary?: string;
  secondary?: string;
}

export interface Item {
  label: string;
  value: string | ItemValue;
}

export interface Section {
  items: Item[];
}

export interface FieldsRemoverMapper {
  channelWise: {
    [key: string]: string[];
  };
  modeOfExecution: {
    [key: string]: string[];
  };
}

export interface SchedulerDetailAuditData {
  executionDate: string;
  totalRecord: number;
  passedRecords: number;
  subSegmentCode: string;
}

export interface SchedulerDetails {
  data: {
    totalRecords: string;
    campaignRunCount: number;
    createdBy: string;
    modeOfExecution: string;
    primaryChannel: string;
    secondaryChannel?: string;
    lastExecutedOn: number;
    campaignCode: string;
    campaignName: string;
    segmentName: string;
    campaignStartDate: string;
    campaignEndDate: string;
    status: string;
    executionStartDate: string;
    frequency: string;
    cronExpDesc: string;
    template: (
      | {
          channel: string;
          templateName: string;
          templateCode: string;
          wabaNumber: string;
        }
      | {
          channel: string;
          templateName: string;
          templateCode: string;
          wabaNumber?: undefined;
        }
    )[];
    camapaignAudit: SchedulerDetailAuditData[];
  };
}

export interface ColumnConfig {
  [key: string]: {
    header?: string;
    sort?: boolean | false;
    width?: string | number;
    link?: boolean;
    imgsrc?: string;
    path?: string;
    type?: string;
    linkIcon?: boolean;
    url?: string;
    idKey?: string;
    colStyle?: object;
    headerStyle?: object;
    name?: string;
    validRole?: string[];
    filter?: boolean;
    value?: string;
    validPaths?: string[];
    hasColumnFilter?: boolean;
    options?: Array<object | string>;
    optionLabel?: string;
    showMatchModes?: boolean;
    showAddButton?: boolean;
    showOperator?: boolean;
    showApplyButton?: boolean;
    hideOnClear?: boolean;
    filterType?: string;
    flagKey?: string;
    frozen?: boolean;
    isImageVisibleKey?: string;
  };
}

export interface SchedulerFailedQC extends IApiResponse {
  data?: string;
}

export interface ReschedulePopup {
  type: string;
  data:
    | { minDate: Date; maxDate: Date; resumeDate: Date }
    | { executionDate: string; startTime: Date; segmentType: string };
  rowData?: ISchedulerFilteredData;
}
