<div class="pagination-rows">
  <div>
    <span class="row-gap">Rows Per Page</span>
    <select [(ngModel)]="rows" name="rows" id="rows" (change)="changeRow()">
      <option value="4">4</option>
      <option value="6">6</option>
      <option value="8">8</option>
    </select>
  </div>

  <div>
    <pagination
      [ngModel]="currentPage"
      [boundaryLinks]="true"
      [maxSize]="maxSize"
      [totalItems]="paginationFilter.totalElements"
      [itemsPerPage]="paginationFilter.pageSize"
      (pageChanged)="pageChanged($event)"
      previousText="&lsaquo;"
      nextText="&rsaquo;"
      firstText=""
      lastText=""
    >
    </pagination>
  </div>
</div>
